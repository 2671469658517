import { Icon, Table, Tooltip } from "@intility/bifrost-react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";

interface LicenseErrorProps {
  error?: string | null;
}

const LicenseError: FC<LicenseErrorProps> = ({ error }) => {

  const showErrorTooltip = () => {
    if (error) {
      switch (error){
        case "CountViolation":
          return "There aren't enough available licenses for one of the products that's specified in the group. You need to either purchase more licenses for the product or free up unused licenses from other users or groups.";
        case "MutuallyExclusiveViolation":
          return "One of the products that's specified in the group contains a service plan that conflicts with another service plan that's already assigned to the user via a different product. Some service plans are configured in a way that they can't be assigned to the same user as another, related service plan.";
        case "DependencyViolation":
          return "One of the products that's specified in the group contains a service plan that must be enabled for another service plan, in another product, to function. This error occurs when Azure AD attempts to remove the underlying service plan. For example, this can happen when you remove the user from the group.";
        case "ProhibitedInUsageLocationViolation":
          return "Some Microsoft services aren't available in all locations because of local laws and regulations. Before you can assign a license to a user, you must specify the Usage location property for the user. You can specify the location under the User > Profile > Edit section in the Azure portal.";
        case "UniquenessViolation":
          return "Non-unique proxy address in Exchange Online, proxy address is already being used.";
      }
    }
  }

  if (error) {
    return (
      <Table.Cell>
        {error}{" "}
        <Tooltip content={showErrorTooltip()}>
          <Icon color="var(--bff-theme)" icon={faInfoCircle} />
        </Tooltip>
      </Table.Cell>
    )
  } else {
    return (
      <Table.Cell>
        Loading error
      </Table.Cell>
    )
  }
}

export default LicenseError;
