import { FC } from 'react';
import styles from './ConnectMarketplace.module.css';
import { Button, Icon, Message } from '@intility/bifrost-react';
import { faLink } from '@fortawesome/free-solid-svg-icons';

interface ConnectMarketplaceProps {
  setShowConnectedMarketplaceModal: (showConnectMarketplace: boolean) => void;
}

export const ConnectMarketplace: FC<ConnectMarketplaceProps> = ({ setShowConnectedMarketplaceModal }) => {
  const handleClick = () => () => {
    setShowConnectedMarketplaceModal(true);
  };

  return (
    <div className={styles.tabsPage}>
      <Message header="Activate marketplace" className={styles.connectMessage}>
        Marketplace needs to be manually activated for each costumer before subscriptions can be connected.
      </Message>
      <Button type="button" state="default" variant="filled" className={styles.connectButton} onClick={handleClick()}>
        <Icon icon={faLink}></Icon> Connect to Marketplace
      </Button>
    </div>
  );
};

export default ConnectMarketplace;
