import { FC, useEffect } from 'react';
import styles from './TenantsDropDown.module.css';
import { Tenant } from 'models';
import Select, { selectStyles } from '@intility/bifrost-react-select';
import { useNavigate } from 'react-router-dom';
import { addToLocalStorage, getArrayFromLocalStorage } from './utils/localStorage';

interface TenantsDropDownProps {
  tenants: Tenant[];
  isLoading?: boolean;
  selectedTenant: { label: string; value: string; domain: string } | null;
  setSelectedTenant: (item: { label: string; value: string; domain: string } | null) => void;
  setRecentTenants: (tenants: { label: string; value: string; domain: string }[]) => void;
}

const TenantsDropDown: FC<TenantsDropDownProps> = ({
  tenants,
  isLoading,
  selectedTenant,
  setSelectedTenant,
  setRecentTenants,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedTenants = getArrayFromLocalStorage<{ label: string; value: string; domain: string }>(
      'recentTenants',
      []
    );
    setRecentTenants(storedTenants);
  }, []);

  const options = tenants.map((tenant: Tenant) => ({
    label: tenant.name,
    value: tenant.id,
    domain: tenant.domain,
  }));

  const handleSelectedTenant = (item: any) => {
    const updatedTenants = addToLocalStorage('recentTenants', item);
    setRecentTenants(updatedTenants);
    navigate(`/tenant/${item.value}`);
  };

  const formatOptionLabel = ({ label, domain }: { label: string; domain: string }) => (
    <div className={styles.optionLabel}>
      <div>{label}</div>
      <div className={styles.domain}>{domain}</div>
    </div>
  );

  const mergeStyles = (base, custom) => (provided, state) => ({
    ...base(provided, state),
    ...custom(provided, state),
  });

  const customStyles = {
    // Appending Custom style for the dropdown indicator (arrow) to Bifrost's custom styles
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? 'blue' : 'var(--bfc-success)',
    }),
  };

  return (
    <div>
      <Select
        label={'Tenants'}
        options={options}
        placeholder={'Search by customer name'}
        hideLabel
        loading={isLoading}
        value={selectedTenant}
        formatOptionLabel={formatOptionLabel}
        onChange={(item) => {
          setSelectedTenant(item);
          handleSelectedTenant(item);
        }}
        className={styles.select}
        styles={{
          ...selectStyles,
          dropdownIndicator: mergeStyles(selectStyles.dropdownIndicator, customStyles.dropdownIndicator),
        }}
      />
    </div>
  );
};

export default TenantsDropDown;
