import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';

async function removeAlsoGroupConfig(groupId: string | undefined, AlsoGroupSubscription: AlsoGroupSubscription) {
  return authorizedFetch(
    BaseAPIEndpoint + `Also/subscription/${AlsoGroupSubscription.subscriptionId}/groupConnection/${groupId}`,
    {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
      },
    }
  );
}

/**
 * Hook that removes the selected subscriptionConnection.
 * @returns {Object} removeAlsoGroupConfiguration, loading, error
 */
export const useRemoveAlsoGroupConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const removeAlsoGroupConfiguration = useCallback(
    async (groupId: string | undefined, AlsoGroupSubscription: AlsoGroupSubscription) => {
      console.log('starting removeAlsoGroupConfiguration');
      setLoading(true);
      try {
        const response = await removeAlsoGroupConfig(groupId, AlsoGroupSubscription);
        console.log('response', response);

        setLoading(false);
        if (response) {
          return true;
        } else {
          setError(true);
          console.log('error - no response', response);
        }
      } catch (errorObj) {
        setError(true);
        console.error('error', errorObj);
      } finally {
        setLoading(false);
        console.log('finished (finally) removeAlsoGroupConfiguration');
      }
    },
    []
  );

  return {
    removeAlsoGroupConfiguration,
    loading,
    error,
  };
};
