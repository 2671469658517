import { useState } from 'react';
import { Card, Skeleton } from '@intility/bifrost-react';
import { useUserLicenseDetails } from '../../helpers/hooks';
import UserCard from './components/userCard/UserCard';
import { ErrorBoundary } from '../../components';
import UserForm from './components/userForm/UserForm';
import styles from './UserInfo.module.css';

const UserDetails = () => {
  const { error, loading, getUserLicenseDetails } = useUserLicenseDetails();
  const [userDetails, setUserDetails] = useState();

  return (
    <div className={styles.page + ' bfl-page-padding'}>
      <h1 style={{ marginTop: 0 }} className="bf-h1">
        Assigned licenses
      </h1>
      <ErrorBoundary>
        <UserForm error={error} setUserDetails={setUserDetails} getUserLicenseDetails={getUserLicenseDetails} />
      </ErrorBoundary>
      {loading && (
        <div style={{ width: 900 }}>
          <Skeleton repeat={2}>
            <Card>
              <Card.Title>
                <Skeleton.Text />
              </Card.Title>
              <Card.Content>
                <Skeleton.Text />
                <Skeleton.Text />
              </Card.Content>
            </Card>
          </Skeleton>
        </div>
      )}
      {userDetails && <UserCard userDetail={userDetails} />}
    </div>
  );
};
export default UserDetails;
