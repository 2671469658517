import { FC } from 'react';
import { Link } from 'react-router-dom';
import { FloatingMessage, Nav } from '@intility/bifrost-react';
import SideNav from './SideNav';
import { ThemePicker } from 'components/common/themeSelect/ThemePicker';
import logo from '../../assets/PlatformManagerIcon.svg';

interface NavigationProps {
  children: React.ReactNode;
}

const Navigation: FC<NavigationProps> = ({ children }) => {
  const TIMEOUT_DURATION = 10000;
  return (
    <Nav
      logo={
        <Link to="/" className="bf-neutral-link">
          <Nav.Logo logo={logo}>License service</Nav.Logo>
        </Link>
      }
      top={<ThemePicker />}
      side={<SideNav />}
    >
      <FloatingMessage max={4} timeout={TIMEOUT_DURATION}>
        {children}
      </FloatingMessage>
    </Nav>
  );
};

export default Navigation;
