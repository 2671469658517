import React, { FC } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Checkbox,
  Icon,
  Inline,
  Input,
  Message,
  Modal,
  Spinner,
  useFloatingMessage,
} from '@intility/bifrost-react';
import styles from './ManageSubsFormDrawerContent.module.css';
import { OptionType, SubscriptionConnection } from 'models';
import Select from '@intility/bifrost-react-select';
import { Group } from '@microsoft/microsoft-graph-types';
import { usePostGroupConfigurations, useRemoveExtSubscription } from 'helpers/hooks';
import { useState, useMemo, useEffect } from 'react';
import { patchSubscriptionDetails } from '../../../../../../../api/fetchers/patchSubscriptionDetails';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';
import { postHandledMSSubscription } from 'api/fetchers/postHandledMSSubscription';
import { deleteHandledMSSubscription } from 'api/fetchers/deleteHandledMSSubscription';
import { faMinus, faPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { TranslateStatuscode } from './translateStatuscode';
import { patchExternalSubscription } from 'api/fetchers/patchExternalSubscription';
import { MSProps } from 'models/TenantConfigProps';

interface ManageDrawerFormProps {
  selectedSubscription: any;
  tenantId: string;
  groups: Group[];
  setShowManageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  usage?: 'connected' | 'unconnected' | 'documented';
  handleMSMutate: () => void;
  MSProps: MSProps;
}

const ManageSubsDrawer: FC<ManageDrawerFormProps> = React.memo(
  ({ selectedSubscription, tenantId, setShowManageDrawer, usage, MSProps }) => {
    const subscription: SubscriptionConnection = selectedSubscription;
    const { groups, handleMSMutate } = MSProps;
    const [groupError, setGroupError] = useState<boolean>(false);
    const [group, setGroup] = useState<OptionType | null>(null);
    const [groupBasedLicensing, setGroupBasedLicensing] = useState<boolean>(true);
    const [selectedButton, setSelectedButton] = useState('Sub');
    const { loading, createGroupConfig } = usePostGroupConfigurations();
    const [subscriptionValue, setSubscriptionValue] = useState<string>(subscription?.name || '');
    const [subscriptionDetailsChanged, setSubscriptionDetailsChanged] = useState(false);
    const [connectSubscriptionChanged, setConnectSubscriptionChanged] = useState(false);
    const [handledChanged, setHandledChanged] = useState<boolean>(false);
    const [handled, setHandled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reminderCheckBox, setReminderCheckBox] = useState<boolean>(false);
    const [reminderQuantity, setReminderQuantity] = useState<number>(0);
    const [reminderChanged, setReminderChanged] = useState<boolean>(false);
    const [autoRenewal, setAutoRenewal] = useState<boolean>(selectedSubscription.autoRenewEnabled || false);
    const [quantity, setQuantity] = useState<number>(subscription.quantity || 0);
    const [modalOpen, setModalOpen] = useState(false);
    const [licenseServiceId, setLicenseServiceId] = useState<string>(subscription.licenseServiceId || '');
    const { removeExtSubscription } = useRemoveExtSubscription();
    const { showFloatingMessage } = useFloatingMessage();
    const [SubscriptionModal, setSubScriptionModal] = useState(false);
    const [selectedSubscriptionStatus, setSelectedSubscriptionStatus] = useState<string>('');

    const showMessage = (
      message: string,
      state: 'default' | 'success' | 'warning' | 'alert' | 'neutral' | undefined
    ) => {
      showFloatingMessage(message, { state: state });
    };

    const handleRemoveExternal = () => {
      removeExtSubscription(licenseServiceId).then(() => {
        setModalOpen(false);
        showMessage('Documented Sub deleted', 'warning');
        setShowManageDrawer(false);
        handleMSMutate();
      });
    };

    const crementHandler = (type: 'plus' | 'minus') => {
      if (type === 'plus' && quantity !== undefined) {
        setQuantity(quantity + 1);
      } else if (type === 'minus' && quantity !== undefined && quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    useEffect(() => {
      if (selectedSubscription) {
        setReminderCheckBox(selectedSubscription.reminderQuantity > 0);
        setReminderQuantity(selectedSubscription.reminderQuantity || 0);
        setQuantity(selectedSubscription.quantity || 1);
        setLicenseServiceId(selectedSubscription.licenseServiceId || '');
        setAutoRenewal(selectedSubscription.autoRenewEnabled || false);
      }
    }, [selectedSubscription]);

    useEffect(() => {
      if (subscription.status === 'handled') {
        setHandled(true);
      } else {
        setHandled(false);
      }
    }, [subscription.status]);

    useEffect(() => {
      if (reminderChanged && !reminderCheckBox) {
        setReminderQuantity(0);
      }
    }, [reminderChanged, reminderCheckBox]);

    useEffect(() => {
      setSubscriptionValue(subscription?.name || '');
    }, [subscription]);

    const handleCancel = () => {
      setGroup(null);
      setGroupBasedLicensing(true);
      setShowManageDrawer(false);
      setIsLoading(false);
      setSubscriptionDetailsChanged(false);
      setConnectSubscriptionChanged(false);
      setReminderChanged(false);
      setHandledChanged(false);
      setReminderCheckBox(false);
      setQuantity(subscription.quantity);
      setAutoRenewal(selectedSubscription.autoRenewEnabled);
      setSelectedSubscriptionStatus('');
    };

    const groupOptions = useMemo(() => {
      return groups.map((groupItem) => ({
        value: groupItem.id || '',
        label: groupItem.displayName || '',
      }));
    }, [groups]);

    const handleFormSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (subscriptionDetailsChanged && subscription) {
        setIsLoading(true);
        const hasChanges = subscriptionValue || (quantity !== undefined && quantity > 1) || autoRenewal !== undefined;

        if (hasChanges) {
          try {
            const subConfig = await patchSubscriptionDetails(
              tenantId,
              subscription.subscriptionId,
              subscriptionValue,
              quantity,
              autoRenewal,
              undefined
            );

            if (subConfig) {
              setGroup(null);
              setShowManageDrawer(false);
              showMessage('Subscription details updated', 'success');
            }
          } catch (error) {
            console.log(error);
            showMessage(`Failed to update subscription details: ${JSON.stringify(error)}`, 'alert');
          } finally {
            setIsLoading(false);
            handleMSMutate();
          }
        } else {
          console.log('No changes made to values in subscription details tab');
        }
      }

      if (connectSubscriptionChanged && group) {
        setIsLoading(true);
        try {
          const groupConfig = await createGroupConfig(
            tenantId,
            subscription.subscriptionId,
            group.value,
            selectedButton,
            groupBasedLicensing
          );
          if (groupConfig) {
            setGroup(null);
            setShowManageDrawer(false);
            setIsLoading(false);
            showMessage('Subscription connected', 'success');
          }
        } catch (error) {
          console.log(error);
          showMessage('Failed to connect subscription' + JSON.stringify(error), 'alert');
        }
        setIsLoading(false);
        handleMSMutate();
      }

      if (handledChanged && subscription) {
        if (handled === true) {
          setIsLoading(true);
          try {
            const config = await postHandledMSSubscription(tenantId, subscription.subscriptionId);
            if (config) {
              setShowManageDrawer(false);
              setIsLoading(false);
              showMessage('Subscription marked as handled', 'success');
            }
          } catch (error) {
            console.log(error);
            showMessage('Failed to mark subscription as handled' + JSON.stringify(error), 'alert');
          }
        }
        if (handled === false) {
          setIsLoading(true);
          try {
            const config = await deleteHandledMSSubscription(tenantId, subscription.handledID);
            if (config) {
              setShowManageDrawer(false);
              setIsLoading(false);
              showMessage('Subscription marked as unhandled', 'default');
            }
          } catch (error) {
            console.log(error);
            showMessage('Failed to mark subscription as unhandled' + JSON.stringify(error), 'alert');
          }
        }
        setIsLoading(false);
        handleMSMutate();
      }

      if (reminderChanged) {
        console.log('Reminder changed');
        setIsLoading(true);
        try {
          const subConfig = await patchExternalSubscription(subscription.licenseServiceId, reminderQuantity);
          if (subConfig) {
            setIsLoading(false);
            showMessage('Reminder set', 'success');
          }
        } catch (error) {
          console.log(error);
          showMessage('Failed to set reminder' + JSON.stringify(error), 'alert');
        }
        setIsLoading(false);
        handleMSMutate();
      }

      setReminderChanged(false);
      setHandledChanged(false);
      setSubscriptionDetailsChanged(false);
      setConnectSubscriptionChanged(false);
      handleCancel();
    };

    const handleStatusChange = async (selectedSubscriptionStatus) => {
      if (subscription && selectedSubscriptionStatus) {
        setIsLoading(true);
        try {
          const subConfig = await patchSubscriptionDetails(
            tenantId,
            subscription.subscriptionId,
            undefined,
            undefined,
            undefined,
            selectedSubscriptionStatus
          );
          if (subConfig) {
            setIsLoading(false);
            showMessage('Subscription status updated', 'success');
            setSubScriptionModal(false);
          }
        } catch (error) {
          console.log(error);
          showMessage('Failed to update subscription status' + JSON.stringify(error), 'alert');
        }
        setIsLoading(false);
        handleCancel();
        handleMSMutate();
      }
    };

    const DefineReminder = () => {
      return (
        <>
          <p className="bf-medium bf-strong">Reminder for licence quantity reaching the limit</p>
          <Checkbox
            label={'Set reminder'}
            align="right"
            type="switch"
            checked={reminderCheckBox}
            className={styles.reminderSwitch}
            onChange={() => {
              setReminderCheckBox((prevState) => !prevState);
              setReminderChanged(true);
            }}
            disabled={isLoading || loading}
          />
          <Input
            label={'Limit at which to start sending reminder'}
            placeholder="-Licenses"
            type="number"
            value={reminderQuantity}
            onChange={(e) => {
              setReminderQuantity(Number(e.target.value));
              setReminderChanged(true);
            }}
            disabled={isLoading || loading || !reminderCheckBox}
          />
        </>
      );
    };

    const SubscriptionDetailsTab = () => {
      return (
        <>
          <Input
            label={'Subscription'}
            value={subscriptionValue || ''}
            onChange={(e) => {
              setSubscriptionValue(e.target.value);
              setSubscriptionDetailsChanged(true);
            }}
          />
          <Checkbox
            style={{ marginTop: 16, marginBottom: 8, textAlign: 'center' }}
            label="Auto renewal"
            checked={autoRenewal}
            type="switch"
            onChange={() => {
              setAutoRenewal((prevState) => !prevState);
              setSubscriptionDetailsChanged(true);
            }}
            disabled={isLoading || loading}
          />
          <div className={styles.inputContainer}>
            <p className="bf-medium bf-strong" style={{ marginRight: 38, marginBottom: 0 }}>
              Quantity
            </p>
            <div className={styles.inputButtonContainer}>
              <button
                disabled={quantity === undefined || isLoading || loading}
                type="button"
                className={
                  quantity !== undefined && quantity > 1 ? styles.crementButtons : styles.crementButtonsDisabled
                }
                onClick={() => {
                  crementHandler('minus');
                  setSubscriptionDetailsChanged(true);
                }}
              >
                <Icon icon={faMinus}></Icon>
              </button>
              <Input
                disabled={quantity === undefined}
                className={styles.quantityInput}
                label={'Quantity'}
                hideLabel
                type="number"
                value={quantity !== undefined ? quantity : undefined}
                onChange={(e) => {
                  setQuantity(Number(e.target.value));
                  setSubscriptionDetailsChanged(true);
                }}
              />
              <button
                type="button"
                className={styles.crementButtons}
                onClick={() => {
                  crementHandler('plus');
                  setSubscriptionDetailsChanged(true);
                }}
                disabled={quantity === undefined || isLoading || loading}
              >
                <Icon icon={faPlus}></Icon>
              </button>
            </div>
          </div>
        </>
      );
    };

    const MarkAsHandled = () => {
      return (
        <>
          <p className="bf-medium" style={{ marginBottom: 0 }}>
            Check or uncheck to move the subscription to the handled or unhandled list accordingly.
          </p>
          <Checkbox
            button
            style={{ marginTop: 16, marginBottom: 8 }}
            label="Handled"
            type="checkbox"
            checked={handled}
            onChange={() => {
              setHandled((prevState) => !prevState);
              setHandledChanged(true);
            }}
            disabled={isLoading || loading}
          />
        </>
      );
    };

    const ConnectSubscriptionTab = () => {
      return (
        <>
          <Select
            value={group}
            isClearable
            label="Azure AD group"
            placeholder="- Select group -"
            onBlur={() => setGroupError(false)}
            state={groupError ? 'alert' : 'default'}
            options={groupOptions}
            onChange={(groupOption) => {
              if (groupOption === null) {
                setGroup(null);
              } else {
                setGroup(groupOption);
              }
              setConnectSubscriptionChanged(true); // Track change
            }}
          />
          <Checkbox
            button
            style={{ marginTop: 16, marginBottom: 8 }}
            label="Use group-based licensing"
            checked={groupBasedLicensing}
            type="checkbox"
            onChange={() => {
              setGroupBasedLicensing((prevState) => !prevState);
              setConnectSubscriptionChanged(true); // Track change
            }}
            disabled={isLoading || loading}
          />
          <>
            <p className="bf-label" style={{ marginBottom: 0 }}>
              Order method
            </p>
            <div className={styles.radioButtons}>
              <Checkbox
                button
                type="radio"
                label="Sub"
                className={styles.Checkbox}
                checked={selectedButton === 'Sub'}
                onChange={() => {
                  setSelectedButton('Sub');
                  setConnectSubscriptionChanged(true); // Track change
                }}
                name="radioButtonDemo"
                key="Sub"
                disabled={isLoading || loading}
              />
              <Checkbox
                button
                type="radio"
                label="Sku"
                className={styles.Checkbox}
                checked={selectedButton === 'Sku'}
                onChange={() => {
                  setSelectedButton('Sku');
                  setConnectSubscriptionChanged(true); // Track change
                }}
                name="radioButtonDemo"
                key="Sku"
                disabled={isLoading || loading}
              />
            </div>
          </>
        </>
      );
    };

    const RemoveDocSub = () => {
      return (
        <>
          <p className="bf-medium">Delete the subscription from the documented list.</p>
          <Modal
            className={styles.container}
            onRequestClose={() => setModalOpen(false)}
            header="Delete external subscription"
            isOpen={modalOpen}
          >
            <div className={styles.description}>
              <p>{subscription?.name}</p>
              <p className="bf-strong">This external subscription will be deleted.</p>
            </div>

            <Inline className={styles.buttonGroup}>
              <Button state="alert" variant="filled" onClick={handleRemoveExternal} className={styles.RemoveBtn}>
                Delete
              </Button>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            </Inline>
          </Modal>
          <Button state="alert" variant="outline" title="Remove connection" small onClick={() => setModalOpen(true)}>
            <Icon color="red" icon={faBan} /> Delete subscription
          </Button>
        </>
      );
    };

    const changeSubscriptionStatus = (status) => {
      return (
        <div className={styles.SubscriptionStatusContainer}>
          {status === 'Active' && <Message state="success">State: Active</Message>}
          {status === 'Suspended' && <Message state="warning">State: Suspended</Message>}
          {status === 'Deleted' && <Message state="alert">State: Deleted</Message>}
          <Modal
            className={styles.containerSubscriptionStatus}
            onRequestClose={() => setSubScriptionModal(false)}
            header="Subscription status"
            isOpen={SubscriptionModal}
          >
            <div className={styles.description}>
              <p className="bf-medium">{subscription?.name}</p>

              {selectedSubscriptionStatus === 'Deleted' ? (
                <>
                  <span className="bf-strong">
                    Are you sure you want to permanently delete <Badge state="alert">{subscription?.name}</Badge> ?
                  </span>
                  <p className="bf-strong">This action is irreversible</p>
                </>
              ) : (
                <span className="bf-strong">
                  Change status from{' '}
                  {status === 'Deleted' ? (
                    <Badge state="alert">{status}</Badge>
                  ) : status === 'Suspended' ? (
                    <Badge state="warning">{status}</Badge>
                  ) : (
                    <Badge state="success">{status}</Badge>
                  )}
                  to{' '}
                  {selectedSubscriptionStatus === 'Deleted' ? (
                    <Badge state="alert">{selectedSubscriptionStatus}</Badge>
                  ) : selectedSubscriptionStatus === 'Suspended' ? (
                    <Badge state="warning">{selectedSubscriptionStatus}</Badge>
                  ) : (
                    <Badge state="success">{selectedSubscriptionStatus}</Badge>
                  )}
                  ?
                </span>
              )}
            </div>

            <Inline className={styles.buttonGroup}>
              {selectedSubscriptionStatus === 'Active' && (
                <Button
                  state="default"
                  variant="filled"
                  disabled={isLoading || loading}
                  onClick={() => {
                    handleStatusChange(selectedSubscriptionStatus);
                  }}
                >
                  Activate
                  {(isLoading || loading) && (
                    <Spinner
                      label={'Setting state: ' + selectedSubscriptionStatus + '...'}
                      overlay
                      className={styles.spinner}
                      size={100}
                    />
                  )}
                </Button>
              )}
              {selectedSubscriptionStatus === 'Suspended' && (
                <Button
                  state="alert"
                  variant="outline"
                  disabled={isLoading || loading}
                  onClick={() => {
                    handleStatusChange(selectedSubscriptionStatus);
                  }}
                >
                  Suspend
                  {(isLoading || loading) && (
                    <Spinner
                      label={'Setting state: ' + selectedSubscriptionStatus + '...'}
                      overlay
                      className={styles.spinner}
                      size={100}
                    />
                  )}
                </Button>
              )}
              {selectedSubscriptionStatus === 'Deleted' && (
                <Button
                  state="alert"
                  variant="filled"
                  disabled={isLoading || loading}
                  onClick={() => {
                    handleStatusChange(selectedSubscriptionStatus);
                  }}
                >
                  Delete
                  {(isLoading || loading) && (
                    <Spinner
                      label={'Setting state: ' + selectedSubscriptionStatus + '...'}
                      overlay
                      className={styles.spinner}
                      size={100}
                    />
                  )}
                </Button>
              )}

              <Button
                disabled={isLoading || loading}
                onClick={() => {
                  setSubScriptionModal(false);
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Inline>
          </Modal>

          {status === 'Active' && (
            <div>
              <Button
                state="alert"
                variant="outline"
                title="Suspend connection"
                small
                onClick={() => {
                  setSubScriptionModal(true);
                  setSelectedSubscriptionStatus('Suspended');
                }}
              >
                Suspend
              </Button>{' '}
              <Button
                state="alert"
                variant="filled"
                title="Delete connection"
                small
                onClick={() => {
                  setSubScriptionModal(true);
                  setSelectedSubscriptionStatus('Deleted');
                }}
              >
                Delete
              </Button>
            </div>
          )}
          {status === 'Suspended' && (
            <div>
              <Button
                state="default"
                variant="outline"
                title="Activate connection"
                small
                onClick={() => {
                  setSubScriptionModal(true);
                  setSelectedSubscriptionStatus('Active');
                }}
              >
                Activate
              </Button>{' '}
              <Button
                state="alert"
                variant="filled"
                title="Delete connection"
                small
                onClick={() => {
                  setSubScriptionModal(true);
                  setSelectedSubscriptionStatus('Deleted');
                }}
              >
                Delete
              </Button>
            </div>
          )}
        </div>
      );
    };

    return (
      <form onSubmit={handleFormSubmit}>
        <div className={styles.main}>
          <div className={styles.headerItems}>
            <p className={styles.selectedSubscrip + ' bf-medium'}>{subscription?.name || subscription.skuName}</p>
          </div>

          <Accordion variant="styled" className={styles.accordionStyle}>
            {(usage === 'connected' || usage === 'unconnected') && (
              <>
                <Accordion.Item
                  title={
                    <span className={styles.inlineStatusStyle + ' bf-strong bf-large'}>
                      {TranslateStatuscode(subscription.partnerStatus) === 'Active' && (
                        <Badge state="success">State: Active</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Suspended' && (
                        <Badge state="warning">State: Suspended</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Expired' && (
                        <Badge state="warning">State: Expired</Badge>
                      )}
                      {TranslateStatuscode(subscription.partnerStatus) === 'Deleted' && (
                        <Badge state="alert">State: Deleted</Badge>
                      )}
                    </span>
                  }
                  disabled={
                    loading ||
                    isLoading ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Deleted' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Expired' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Pending' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Disabled' ||
                    TranslateStatuscode(subscription.partnerStatus) === 'Unknown'
                  }
                >
                  {changeSubscriptionStatus(TranslateStatuscode(subscription.partnerStatus))}
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Subscription details</span>}
                  disabled={loading || isLoading}
                >
                  {SubscriptionDetailsTab()}
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Connect subscription </span>}
                  disabled={loading || isLoading}
                >
                  {ConnectSubscriptionTab()}
                </Accordion.Item>
              </>
            )}

            {usage === 'unconnected' && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Mark as handled </span>}
                  disabled={loading || isLoading}
                >
                  {MarkAsHandled()}
                </Accordion.Item>
              </>
            )}
            {usage === 'documented' && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Subscription details </span>}
                  disabled={loading || isLoading}
                >
                  {DefineReminder()}
                </Accordion.Item>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Delete documented Subscription</span>}
                  disabled={loading || isLoading}
                >
                  {RemoveDocSub()}
                </Accordion.Item>
              </>
            )}
          </Accordion>
          <div className={styles.buttonGroup}>
            <Button variant="outline" type="button" onClick={() => handleCancel()} disabled={isLoading || loading}>
              Cancel
            </Button>
            <SpinnerButton isSubmitting={loading || isLoading} buttonText={'Save changes'} />
          </div>
        </div>
      </form>
    );
  }
);

export default ManageSubsDrawer;
