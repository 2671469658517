import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const postHandledMSSubscription = async (tenantId, subscriptionId) => {
  const data = {
    microsoftSubscriptionId: subscriptionId,
  };

  try {
    const response = await authorizedFetch(`${BaseAPIEndpoint}Tenants/${tenantId}/Subscriptions/handledSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.data) {
      throw new Error('Error posting subscription as handled');
    }
    return response;
  } catch (error) {
    console.log('Error posting subscription as handled', error);
    throw error;
  }
};
