import { useState } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Icon, Tooltip } from '@intility/bifrost-react';

const CopyIconButton = ({ copyString }: { copyString: string }) => {
  const [tooltip, setTooltip] = useState('Copy');
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Tooltip content={tooltip} placement="right" visible={showTooltip}>
      <Icon
        icon={faCopy}
        onClick={() => {
          navigator.clipboard.writeText(copyString);
          setTooltip('ID copied!');
        }}
        onMouseEnter={() => {
          setTooltip(`Copy ${copyString}`);
          setShowTooltip(true);
        }}
        onMouseLeave={() => setShowTooltip(false)}
        style={{ marginLeft: 4, marginRight: 8 }}
        color="var(--bfc-theme-2)"
        cursor="pointer"
      />
    </Tooltip>
  );
};

export default CopyIconButton;
