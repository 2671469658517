import { NavLink } from 'react-router-dom';
import { Nav } from '@intility/bifrost-react';
import { faCog, faSignature, faWarning } from '@fortawesome/free-solid-svg-icons';

const SideNav = () => {
  return (
    <>
      <NavLink to="/tenants">
        <Nav.Item title="Tenants page" icon={faCog}>
          Tenants
        </Nav.Item>
      </NavLink>
      <NavLink to="/assignmentErrors">
        <Nav.Item title="Assignment errors" icon={faWarning}>
          Assignment errors
        </Nav.Item>
      </NavLink>
      <NavLink to="/user-details">
        <Nav.Item title="Assigned licenses" icon={faSignature}>
          Assigned licenses
        </Nav.Item>
      </NavLink>
    </>
  );
};
export default SideNav;
