import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { SubscriptionConnection } from '../../models';
import { authorizedFetch } from 'auth/authorizedFetch';

async function removeGroupConfig(groupId: string | undefined, subscriptionConnection: SubscriptionConnection) {
  return authorizedFetch(
    BaseAPIEndpoint +
      `tenants/${subscriptionConnection.tenantId}/SubscriptionConnections/${
        subscriptionConnection.subscriptionId
      }/group/${groupId ?? subscriptionConnection.groupConfigurations[0].id}`,
    {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
      },
    }
  );
}

/**
 * Hook that removes the selected subscriptionConnection.
 *
 */
export const useRemoveGroupConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const removeGroupConfiguration = useCallback(
    async (groupId: string | undefined, subscriptionConnection: SubscriptionConnection) => {
      setLoading(true);
      try {
        const response = await removeGroupConfig(groupId, subscriptionConnection);
        setLoading(false);
        if (response) {
          return true;
        } else {
          setError(true);
        }
      } catch (errorObj) {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    removeGroupConfiguration,
    loading,
    error,
  };
};
