import { FC } from 'react';
import styles from './TenantListItem.module.css';
import { Card } from '@intility/bifrost-react';
import { Link } from 'react-router-dom';

interface Customer {
  label: string;
  value: string;
  domain: string;
}
interface TenantListItemProps {
  viewed: Customer[] | null;
}

const TenantListItem: FC<TenantListItemProps> = ({ viewed }) => {
  return (
    <div className={styles.listItemContainer}>
      {viewed &&
        viewed.map((tenant) => (
          <Card className={styles.listItem} key={tenant.value}>
            <Link className="bf-title-link" to={'/tenant/' + tenant.value}>
              <div className={styles.container}>
                <div className={styles.header}>
                  <h2 className="bf-h3">{tenant.label}</h2>
                  <p>{tenant.domain}</p>
                </div>
              </div>
            </Link>
          </Card>
        ))}
    </div>
  );
};

export default TenantListItem;
