import { PropsWithChildren, useEffect } from 'react';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, useMsalAuthentication } from '@azure/msal-react';

import { msalInstance } from './authConfig';

const loginRequest = {
  scopes: ['.default'],
};

const Template = ({ children }: PropsWithChildren) => {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error, login]);

  return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
};

const Authentication = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Template>{children}</Template>
    </MsalProvider>
  );
};

export default Authentication;
