import { FC } from 'react';
import styles from './OrderSubmitPage.module.css';
import { Icon } from '@intility/bifrost-react';
import Select from "@intility/bifrost-react-select";
import { Control, Controller, SetFieldValue, WatchInternal } from 'react-hook-form';
import { countryCodeOptions } from '../utils/CountryCode';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import DateSelect from './DateSelect';
import { datePickerLogic } from '../utils/datePickerLogic';
import { OrderAvailability } from 'models/OrderAvailability';
import { useCSPSubscriptions } from 'helpers/hooks';


interface OrderSubmitPageTwoProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  watch: WatchInternal<any>;
  isAvailabilityLoading: boolean;
  isAvailability: OrderAvailability[] | undefined;
  tenantId: string;
  availability: OrderAvailability | null;
}

const OrderSubmitPageTwo: FC<OrderSubmitPageTwoProps> = ({
  control,
  isAvailabilityLoading,
  isAvailability,
  tenantId,
  setValue,
  availability,
  watch,
}) => {
  const { data: CSPSubscription } = useCSPSubscriptions(tenantId);
  const cleansedData = datePickerLogic(availability, CSPSubscription);

  return (
    <div className={styles.pageMainContainer}>
      <div className={styles.formHeader}>
        <div className={styles.iconContainer}>
          <Icon icon={faFileInvoiceDollar} className={styles.headerIcon}></Icon>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.customh5BecauseBFSPadding + ' bf-h5'}>Billing details</span>
          <span className="bf-medium bf-strong">Step 2 of 3</span>
        </div>
      </div>
      <div className={styles.linees}></div>
      <Controller
        name="countryCode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            label={'Choose country code'}
            placeholder={'country code'}
            value={value}
            onChange={onChange}
            options={countryCodeOptions}
            className={styles.basicSelect}
          />
        )}
      />

      <Controller
        name="availability"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            label={'Choose term & billing frequency'}
            placeholder={'frequency'}
            value={value}
            onChange={onChange}
            isLoading={isAvailabilityLoading}
            options={isAvailability}
            className={styles.basicSelect}
            isDisabled={isAvailabilityLoading || !watch('countryCode')}
          />
        )}
      />
      <DateSelect cleansedData={cleansedData} control={control} setValue={setValue} availability={availability} />
    </div>
  );
};
export default OrderSubmitPageTwo;
