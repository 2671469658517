import React, { useCallback, useEffect, useState } from 'react';
import styles from './TenantConfiguration.module.css';
import {
  useCSPSubscriptions,
  useExternalSubscriptions,
  useExternalSubscriptionsInfo,
  useGetAdobe,
  useGroups,
  useSubscriptionConnection,
  useSubscriptions,
  useTenants,
} from '../../helpers/hooks';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import TenantHeader from './components/tenantView/components/TenantHeader';
import TenantView from './components/tenantView/TenantView';
import { AlsoConnection, AlsoSubscription } from 'models/AlsoMarketPlace';
import { BackButton, Button, Drawer } from '@intility/bifrost-react';
import OrderSubscriptionForm from './components/orderSubscriptionForm/OrderSubscriptionForm';
import { useAlsoSubscriptions } from 'helpers/hooks/useAlsoSubscriptions';
import { useAlsoConnectedSubscriptions } from 'helpers/hooks/useAlsoConnectedSubscriptions';
import { fetchAlsoConnectionID } from 'api/fetchers/fetchAlsoConnectionID';
import { useAlsoHandledUnconnectedSubscriptions } from 'helpers/hooks/useAlsoHandledUncSubs';
import { useMicrosoftHandledUnconnectedSubscriptions } from 'helpers/hooks/useMicrosoftHandledUncSubs';
import { Subscription } from 'models';
import { MarketProps, MSProps } from 'models/TenantConfigProps';
import { cache } from 'swr/_internal';

type RouteParams = {
  id: string;
};

export const ConfigureTenantPage = () => {
  const [alsoConnectionID, setAlsoConnectionID] = useState<AlsoConnection | undefined>();
  const [alsoConnectionLoading, setAlsoConnectionLoading] = useState(false);
  const [showConnectedMarketplaceModal, setShowConnectedMarketplaceModal] = useState(false);
  const [orderSubDrawer, setOrderSubDrawer] = useState(false);
  const [orderStateCancel, setOrderStateCancel] = useState(false);
  const { id: tenantId } = useParams<RouteParams>();
  const assuredTenantId = tenantId!;
  const [MarketplaceNumber, setMarketplaceNumber] = useState<number>(0);
  const [amountOfConnectedMarketPlace, setAmountOfConnectedMarketPlace] = useState<number>(0);
  const [amountOfUnconnectedMarketPlace, setAmountOfUnconnectedMarketPlace] = useState<number>(0);
  const [MirosoftNumber, setMirosoftNumber] = useState<number>(0);
  const [amountOfConnectedMS, setAmountOfConnectedMS] = useState<number>(0);
  const [amountOfUnhandledUnconnectedMS, setAmountOfUnhandledUnconnectedMS] = useState<number>(0);
  const [amountOfHandledUnconnectedMS, setAmountOfHandledUnconnectedMS] = useState<number>(0);
  const [amountOfUnhandledUnconnectedALSO, setAmountOfUnhandledUnconnectedALSO] = useState<number>(0);
  const [amountOfHandledUnconnectedALSO, setAmountOfHandledUnconnectedALSO] = useState<number>(0);
  const [amountOfDocumentedMS, setAmountOfDocumentedMS] = useState<number>(0);
  const [HandledMicrosoftSubs, setHandledMicrosoftSubs] = useState<Subscription[]>();
  const [UnhandledMicrosoftSubs, setUnhandledMicrosoftSubs] = useState<Subscription[]>();
  const [HandledAlsoSubs, setHandledAlsoSubs] = useState<AlsoSubscription[]>();
  const [UnhandledAlsoSubs, setUnhandledAlsoSubs] = useState<AlsoSubscription[]>();
  const [fetchAlsoDetails, setFetchAlsoDetails] = useState(false);

  const { data: groups } = useGroups(assuredTenantId);
  const { data: externalSubscriptions, error } = useExternalSubscriptions(assuredTenantId);
  const { data: subscriptions, isLoading: isLoadingUnconnectedSubscriptions } = useSubscriptions(assuredTenantId);
  const { data: CSPSubscription, isLoading: isLoadingCSPSubscriptions } = useCSPSubscriptions(assuredTenantId);
  const { data: subscriptionConnection, isLoading: isLoadingConnectedSubscriptions } =
    useSubscriptionConnection(assuredTenantId);
  const { data: externalSubscription, isLoading: isLoadingExternalSubscriptions } =
    useExternalSubscriptionsInfo(assuredTenantId);
  const { data: tenants } = useTenants();
  const { data: alsoUnconnectedSubscription, isLoading: isLoadingAlsoUnconnectedSubscription } = useAlsoSubscriptions(
    alsoConnectionID?.alsoCompanyAccountId
  );
  const { data: alsoHandledUncSubs, isLoading: isLoadingAlsoHandledUnconnectedSubscriptions, error: ErrorsalsoHandledUncSubs } =
    useAlsoHandledUnconnectedSubscriptions(assuredTenantId);
  const { data: microsoftHandledUncSubs, isLoading: isLoadingMicrosoftHandledUnconnectedSubscriptions } =
    useMicrosoftHandledUnconnectedSubscriptions(assuredTenantId);
  const { data: adobeGroups, isLoading: isLoadingAdobe, mutate: mutateAdobe } = useGetAdobe(assuredTenantId);
  const { data: alsoConnectedSubscription, isLoading: isLoadingAlsoConnectedSubscription } =
    useAlsoConnectedSubscriptions(assuredTenantId, fetchAlsoDetails);

  useEffect(() => {
    const fetchData = async () => {
      cache.delete('subscriptions');

      await Promise.all([
        mutate('groups'),
        mutate('subscriptions'),
        mutate('CSPSubscriptions'),
        mutate('SubscriptionConnection'),
        mutate('externalSubscriptions'),
        mutate('externalSubscriptionsInfo'),
        mutate('ExternalSubscriptionInfoDto'),
        mutate('microsoftHandledUncSubs'),
      ]);
    };

    fetchData();
    setMarketplaceNumber(0);
    setAmountOfConnectedMarketPlace(0);
    setAmountOfUnconnectedMarketPlace(0);
    setMirosoftNumber(0);
    setAmountOfConnectedMS(0);
    setAmountOfUnhandledUnconnectedMS(0);
    setAmountOfHandledUnconnectedMS(0);
    setAmountOfUnhandledUnconnectedALSO(0);
    setAmountOfHandledUnconnectedALSO(0);
    setAmountOfDocumentedMS(0);
  }, [assuredTenantId]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        mutate('alsoSubscriptions'),
        mutate('alsoConnectedSubscriptions'),
        mutate('alsoHandledUncSubs'),
      ]);
    };

    if (alsoConnectionID) {
      fetchData();
    }
  }, [alsoConnectionID]);

  useEffect(() => {
    mutateAdobe();
  }, []);

  useEffect(() => {
    cache.delete('subscriptions');
    mutate('subscriptions');
    cache.delete('CSPSubscriptions');
    mutate('CSPSubscriptions');
    cache.delete('SubscriptionConnection');
    mutate('SubscriptionConnection');
    cache.delete('externalSubscriptions');
    mutate('externalSubscriptions');
    cache.delete('externalSubscriptionsInfo');
    mutate('externalSubscriptionsInfo');
    cache.delete('ExternalSubscriptionInfoDto');
    mutate('ExternalSubscriptionInfoDto');
    cache.delete('microsoftHandledUncSubs');
    mutate('microsoftHandledUncSubs');
    setMarketplaceNumber(0);
    setAmountOfConnectedMarketPlace(0);
    setAmountOfUnconnectedMarketPlace(0);
    setMirosoftNumber(0);
    setAmountOfConnectedMS(0);
    setAmountOfUnhandledUnconnectedMS(0);
    setAmountOfHandledUnconnectedMS(0);
    setAmountOfUnhandledUnconnectedALSO(0);
    setAmountOfHandledUnconnectedALSO(0);
    setAmountOfDocumentedMS(0);
  }, [assuredTenantId]);

  useEffect(() => {
    if (assuredTenantId) {
      const checkAlsoConnection = async () => {
        setAlsoConnectionLoading(true);
        try {
          const response = await fetchAlsoConnectionID(assuredTenantId);
          if (response.data !== null && response.data !== undefined && response.code !== 204) {
            setAlsoConnectionID(response.data);
            setFetchAlsoDetails(true);
          } else {
            setAlsoConnectionID(undefined);
            setFetchAlsoDetails(false);
          }
        } catch (error) {
          console.error('Error fetching Also Connection ID', error);
          setAlsoConnectionID(undefined);
        }
        setAlsoConnectionLoading(false);
      };

      checkAlsoConnection();
    }
  }, []);

  let tenant;

  const checkTenant = async () => {
    if (tenants) {
      tenant = tenants.find((tenant) => tenant.id === assuredTenantId);
    } else {
      tenant = 'Tenant';
    }
  };
  checkTenant();

  useEffect(() => {
    if (alsoUnconnectedSubscription && alsoHandledUncSubs) {
      CheckHandledAlsoSubs(alsoHandledUncSubs, alsoUnconnectedSubscription, setUnhandledAlsoSubs, setHandledAlsoSubs);
    }
    if (subscriptions && microsoftHandledUncSubs) {
      CheckHandledMicrosoftSubs(
        microsoftHandledUncSubs,
        subscriptions,
        setHandledMicrosoftSubs,
        setUnhandledMicrosoftSubs
      );
    }
  }, [alsoHandledUncSubs, microsoftHandledUncSubs, subscriptions, alsoUnconnectedSubscription]);

  const CheckHandledAlsoSubs = (
    alsoHandledUncSubs,
    alsoUnconnectedSubscription,
    setUnhandledAlsoSubs,
    setHandledAlsoSubs
  ) => {
    const handledSubscriptionIds = alsoHandledUncSubs.map((sub) => sub.alsoSubscriptionId);

    const handledSubscriptions = alsoUnconnectedSubscription
      .filter((sub) => handledSubscriptionIds.includes(sub.accountId))
      .map((sub) => {
        const handledSub = alsoHandledUncSubs.find((item) => item.alsoSubscriptionId === sub.accountId);

        if (handledSub) {
          return {
            ...sub,
            ...handledSub,
            handledStatus: 'handled',
            accountId: sub.accountId,
            handledId: handledSub.id,
          };
        }
        return null;
      })
      .filter(Boolean);

    const unhandledSubscriptions = alsoUnconnectedSubscription
      .filter((sub) => !handledSubscriptionIds.includes(sub.accountId))
      .map((sub) => {
        const unhandledSub = alsoHandledUncSubs.find((item) => item.alsoSubscriptionId === sub.accountId);

        return {
          ...sub,
          handledStatus: 'unhandled',
          accountId: sub.accountId,
          handledId: unhandledSub ? unhandledSub.id : null,
        };
      });

    setHandledAlsoSubs(handledSubscriptions);
    setUnhandledAlsoSubs(unhandledSubscriptions);
  };

  const CheckHandledMicrosoftSubs = (
    microsoftHandledUncSubs,
    subscriptions,
    setHandledMicrosoftSubs,
    setUnhandledMicrosoftSubs
  ) => {
    const handledSubscriptionIds = microsoftHandledUncSubs.map((sub) => sub.microsoftSubscriptionId);
    const handledSubscriptions = subscriptions
      .filter((sub) => handledSubscriptionIds.includes(sub.subscriptionId))
      .map((sub) => {
        const handledSub = microsoftHandledUncSubs.find(
          (handled) => handled.microsoftSubscriptionId === sub.subscriptionId
        );
        return { ...sub, status: 'handled', handledID: handledSub.id };
      });
    const unhandledSubscriptions = subscriptions
      .filter((sub) => !handledSubscriptionIds.includes(sub.subscriptionId))
      .map((sub) => ({ ...sub, status: 'unhandled' }));

    setHandledMicrosoftSubs(handledSubscriptions);
    setUnhandledMicrosoftSubs(unhandledSubscriptions);
  };

  const handleMSMutate = useCallback(() => {
    mutate('subscriptions');
    mutate('CSPSubscriptions');
    mutate('SubscriptionConnection');
    mutate('groups');
    mutate('externalSubscriptions');
    mutate('externalSubscriptionsInfo');
    mutate('microsoftHandledUncSubs');
  }, []);

  const handleMarketMutate = useCallback(() => {
    mutate('groups');
    mutate('alsoConnectionID');
    mutate('alsoSubscriptions');
    mutate('alsoConnectedSubscriptions');
    mutate('alsoHandledUncSubs');
  }, []);

  const renderGroupName = useCallback(
    (groupId) => {
      if (externalSubscription) {
        const group = externalSubscription.groups.find((_group) => _group.id === groupId);
        if (group) {
          return group.displayName;
        } else {
          return ' - Not connected -';
        }
      }
    },
    [externalSubscription]
  );

  const MSProps: MSProps = {
    externalSubscriptions,
    subscriptionConnections: subscriptionConnection,
    subscriptions,
    renderGroupName,
    isLoadingUnconnectedSubscriptions,
    isLoadingConnectedSubscriptions,
    isLoadingExternalSubscriptions,
    error,
    assuredTenantId,
    groups: groups || [],
    externalSubscription,
    CSPSubscription,
    isLoadingCSPSubscriptions,
    handleMSMutate,
    MirosoftNumber,
    setMirosoftNumber,
    amountOfConnectedMS,
    setAmountOfConnectedMS,
    amountOfUnhandledUnconnectedMS,
    setAmountOfUnhandledUnconnectedMS,
    amountOfHandledUnconnectedMS,
    setAmountOfHandledUnconnectedMS,
    amountOfDocumentedMS,
    setAmountOfDocumentedMS,
    HandledMicrosoftSubs,
    UnhandledMicrosoftSubs,
    adobeGroups,
    isLoadingAdobe,
    isLoadingMicrosoftHandledUnconnectedSubscriptions,
    ErrorsalsoHandledUncSubs,
  };

  const MarketProps: MarketProps = {
    groups: groups || [],
    assuredTenantId,
    //Marketplace
    alsoConnectionID,
    alsoConnectionLoading,
    setAlsoConnectionLoading,
    alsoUnconnectedSubscription,
    isLoadingAlsoUnconnectedSubscription,
    alsoConnectedSubscription,
    isLoadingAlsoConnectedSubscription,
    handleMarketMutate,
    MarketplaceNumber,
    setMarketplaceNumber,
    amountOfConnectedMarketPlace,
    setAmountOfConnectedMarketPlace,
    amountOfUnconnectedMarketPlace,
    setAmountOfUnconnectedMarketPlace,
    amountOfHandledUnconnectedALSO,
    setAmountOfHandledUnconnectedALSO,
    amountOfUnhandledUnconnectedALSO,
    setAmountOfUnhandledUnconnectedALSO,
    HandledAlsoSubs,
    UnhandledAlsoSubs,
    isLoadingAlsoHandledUnconnectedSubscriptions,
  };

  return (
    <div className={styles.page}>
      <BackButton />

      <div className={styles.content}>
        <TenantHeader
          tenantDomain={tenant.domain}
          msTenantName={tenant.name}
          msTenantID={assuredTenantId}
          showConnectedMarketplaceModal={showConnectedMarketplaceModal}
          setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal}
          setOrderSubDrawer={setOrderSubDrawer}
          MarketProps={MarketProps}
        />

        <TenantView
          setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal}
          MarketProps={MarketProps}
          MSProps={MSProps}
        />

        <Drawer
          style={{ padding: '0' }}
          overlay
          className={styles.orderDrawer}
          header="Order new subscription"
          isOpen={orderSubDrawer}
          onRequestClose={() => setOrderSubDrawer(false)}
          footer={
            <div style={{ maxHeight: '20px' }}>
              <Button
                className={styles.orderDrawerCancel}
                onClick={() => {
                  setOrderStateCancel(true);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        >
          <OrderSubscriptionForm
            setOrderSubDrawer={setOrderSubDrawer}
            tenantId={assuredTenantId}
            orderStateCancel={orderStateCancel}
            setOrderStateCancel={setOrderStateCancel}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default ConfigureTenantPage;
