import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Input, Message } from '@intility/bifrost-react';
import { Select, CreatableSelect } from '@intility/bifrost-react-select';
import DatePicker from '@intility/bifrost-react-datepicker';
import { useAdobe, usePostExternalSubscription, useVendorName } from '../../../../../../../../helpers/hooks';
import useExternalSubscriptionForm from './useExternalSubscriptionForm';
import { ExternalSubscription, ExternalSubscriptionInfoDto, OptionType } from '../../../../../../../../models';
import styles from './AddExternalSubscriptionForm.module.css';

interface AddExternalVendorFormProps {
  tenantId: string;
  externalSubscription?: ExternalSubscriptionInfoDto;
  setExternalVendorDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddExternalSubscriptionForm: FC<AddExternalVendorFormProps> = ({
  tenantId,
  externalSubscription,
  setExternalVendorDrawer,
}) => {
  let groupOptions = externalSubscription
    ? externalSubscription.groups.map((_group) => ({ value: _group.id || '', label: _group.displayName || '' }))
    : [];
  let vendorOptions = externalSubscription
    ? externalSubscription.vendors.map((_vendor) => ({ value: _vendor.id, label: _vendor.name }))
    : [];
  let subscriptionOptions = externalSubscription
    ? externalSubscription.skus.map((_sku) => ({ value: _sku.skuId, label: _sku.skuName }))
    : [];
  const TIMEOUT_DURATION = 1000;
  const [groupBasedLicensing, setGroupBasedLicensing] = useState<boolean>(false);
  const [adobeChosen, setAdobeChosen] = useState<boolean>(false);
  const [vendorError, setVendorError] = useState<boolean>(false);
  const [skuError, setSkuError] = useState<boolean>(false);
  const [termError, setTermError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [groupError, setGroupError] = useState<boolean>(false);
  const { saveVendorName } = useVendorName();
  const [{ sku, groupId, termDuration, vendor, agreementType, autoRenewal, renewalDate }, setExternalSubscriptionForm] =
    useExternalSubscriptionForm();
  const { sendExternalSubscription, error, loading } = usePostExternalSubscription();
  const { postAdobeGroup } = useAdobe();
  const [reminderCheckBox, setReminderCheckBox] = useState<boolean>(false);
  const [inputLicenseValue, setInputLicenseValue] = useState<number>(externalSubscription?.reminderQuantity || 0);

  const chosenSkuUnits = useMemo(() => {
    if (sku.value) {
      return externalSubscription?.skus.find((chosenSku) => chosenSku.skuId === sku.value);
    }
  }, [externalSubscription?.skus, sku.value]);

  useEffect(() => {
    if (vendor.label === 'Adobe') {
      setAdobeChosen(true);
    } else {
      setAdobeChosen(false);
    }
  }, [vendor.label]);

  useEffect(() => {
    if (groupBasedLicensing || adobeChosen) {
      setExternalSubscriptionForm({ type: 'groupId', groupId: { label: '', value: '' } });
      setGroupBasedLicensing(true);
    }
  }, [adobeChosen, groupBasedLicensing, setExternalSubscriptionForm]);

  const resetForm = () => {
    setExternalSubscriptionForm({ type: 'vendor', vendor: { label: '', value: '' } });
    setExternalSubscriptionForm({ type: 'sku', sku: { label: '', value: '' } });
    setExternalSubscriptionForm({ type: 'agreementType', agreementType: { label: '', value: '' } });
    setExternalSubscriptionForm({ type: 'termDuration', termDuration: { label: '', value: '' } });
    setExternalSubscriptionForm({ type: 'renewalDate', renewalDate: null });
    setExternalSubscriptionForm({ type: 'groupId', groupId: { label: '', value: '' } });
    setExternalSubscriptionForm({ type: 'autoRenewal', autoRenewal: false });
    setExternalVendorDrawer(false);
  };

  const handleCreateVendor = async (newVendor) => {
    if (newVendor) {
      const vendorResponse = await saveVendorName(newVendor);

      if (vendorResponse) {
        const newOption = {
          value: vendorResponse.id,
          label: vendorResponse.name,
        };
        if (vendorOptions) {
          vendorOptions.push(newOption);
          setExternalSubscriptionForm({ type: 'vendor', vendor: newOption });
        }
      }
    }
  };

  const submitAdobe = async () => {
    const adobeGroup = {
      groupId: groupId.value,
      groupName: groupId.label,
      tenantId: tenantId,
    };
    return postAdobeGroup(adobeGroup);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (adobeChosen) {
      submitAdobe().then((response) => {
        if (response) {
          resetForm();
        }
      });
    } else {
      if (!vendor?.value) {
        setVendorError(true);
      }
      if (!sku?.value) {
        setSkuError(true);
      }
      if (!termDuration.value) {
        setTermError(true);
      }
      if (!renewalDate) {
        setDateError(true);
      }
      if (!groupId.value && groupBasedLicensing) {
        setGroupError(true);
      }
      if (vendor && sku && chosenSkuUnits && termDuration && agreementType && renewalDate) {
        const submitObj: ExternalSubscription = {
          skuDTO: {
            skuId: sku.value,
            skuName: sku.label,
          },
          tenantId: tenantId,
          agreementType: agreementType.value,
          termDuration: termDuration.value,
          availableUnits: chosenSkuUnits.availableUnits,
          autoRenewal: autoRenewal,
          renewalDate: renewalDate,
          groupBasedLicensing: groupBasedLicensing,
          groupId: groupId.value.length > 0 ? groupId.value : null,
          vendorId: vendor.value,
          reminderQuantity: reminderCheckBox ? inputLicenseValue : 0,
        };

        sendExternalSubscription(submitObj).then((response) => {
          if (response) {
            setTimeout(() => {
              resetForm();
            }, TIMEOUT_DURATION);
          }
        });
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={styles.main}>
        <div className={styles.vendorGroup}>
          <CreatableSelect
            className={styles.item}
            value={vendor}
            isClearable
            label="Vendor"
            placeholder="- Select vendor or create a new -"
            onBlur={() => setVendorError(false)}
            state={vendorError ? 'alert' : 'default'}
            feedback={vendorError ? 'Please select a vendor' : null}
            options={vendorOptions}
            onCreateOption={(newVendor) => handleCreateVendor(newVendor)}
            onChange={(_vendor: OptionType | null) => {
              if (_vendor === null) {
                setExternalSubscriptionForm({ type: 'vendor', vendor: { label: '', value: '' } });
              } else {
                setExternalSubscriptionForm({ type: 'vendor', vendor: _vendor });
              }
            }}
          />
          <Select
            className={styles.item}
            value={sku}
            isClearable
            isDisabled={adobeChosen}
            label="Subscription"
            placeholder="- Select Subscription -"
            onBlur={() => setSkuError(false)}
            state={skuError ? 'alert' : 'default'}
            feedback={skuError ? 'Please select a subscription' : null}
            options={subscriptionOptions}
            onChange={(sub) => {
              if (sub === null) {
                setExternalSubscriptionForm({ type: 'sku', sku: { label: '', value: '' } });
              } else {
                setExternalSubscriptionForm({ type: 'sku', sku: sub });
              }
            }}
          />
          <Select
            className={styles.item}
            value={termDuration}
            isClearable
            isDisabled={adobeChosen}
            label="Term"
            placeholder="- Select term -"
            onBlur={() => setTermError(false)}
            state={termError ? 'alert' : 'default'}
            feedback={termError ? 'Please select a term' : null}
            options={[
              { value: '1M', label: '1M' },
              { value: '1Y', label: '1Y' },
              { value: '3Y', label: '3Y' },
              { value: 'No binding', label: 'No binding' },
            ]}
            onChange={(termDurationOption) => {
              if (termDurationOption === null) {
                setExternalSubscriptionForm({ type: 'termDuration', termDuration: { label: '', value: '' } });
              } else {
                setExternalSubscriptionForm({ type: 'termDuration', termDuration: termDurationOption });
              }
            }}
          />
          <DatePicker
            className={styles.datePicker}
            selected={renewalDate}
            disabled={adobeChosen}
            filterDate={(date) => {
              return new Date() < date;
            }}
            onBlur={() => setDateError(false)}
            state={dateError ? 'alert' : 'default'}
            feedback={dateError ? 'Please select a date' : null}
            onChange={(date) => {
              if (date === null) {
                setExternalSubscriptionForm({ type: 'renewalDate', renewalDate: null });
              } else {
                setExternalSubscriptionForm({ type: 'renewalDate', renewalDate: date });
              }
            }}
            label="Pick renewal date"
          />
          <Checkbox
            className={styles.switch}
            disabled={adobeChosen}
            type="switch"
            label="Auto renewal"
            checked={autoRenewal}
            onChange={() => setExternalSubscriptionForm({ type: 'autoRenewal', autoRenewal: !autoRenewal })}
          />
        </div>
        <div className={styles.groupWrap}>
          <Select
            className={styles.groupPicker}
            value={groupId}
            isClearable
            label="Azure AD group"
            placeholder="- Select group -"
            onBlur={() => setGroupError(false)}
            state={groupError ? 'alert' : 'default'}
            feedback={groupError ? 'Please select a group' : null}
            options={groupOptions}
            onChange={(groupOption) => {
              if (groupOption === null) {
                setExternalSubscriptionForm({ type: 'groupId', groupId: { label: '', value: '' } });
              } else {
                setExternalSubscriptionForm({ type: 'groupId', groupId: groupOption });
              }
            }}
          />
          <Checkbox
            className={styles.switch}
            disabled={adobeChosen}
            label="Use group-based licensing"
            checked={groupBasedLicensing}
            type="switch"
            onChange={() => setGroupBasedLicensing(() => !groupBasedLicensing)}
          />
        </div>

        <div className={styles.groupWrap}>
          <p className="bf-medium bf-strong">Reminder for licence quantity reaching the limit</p>
          <Checkbox
            label={'Set reminder'}
            align="right"
            type="switch"
            className={styles.reminderSwitch}
            onChange={() => setReminderCheckBox(() => !reminderCheckBox)}
          />
          <Input
            disabled={!reminderCheckBox}
            label={'Limit at which to start sending reminder'}
            placeholder="-Licenses"
            type="number"
            value={inputLicenseValue}
            onChange={(e) => setInputLicenseValue(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
        <Button onClick={resetForm} variant="outline" type="button">
          Cancel
        </Button>
        <Button disabled={loading} variant="filled" type="submit">
          {loading ? 'Loading' : 'Add'}
        </Button>
      </div>
      {error && (
        <Message state="alert" header="Something went wrong">
          Sorry we couldn't connect the AAD Group to the chosen subscription.
        </Message>
      )}
    </form>
  );
};

export default AddExternalSubscriptionForm;
