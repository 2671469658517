import useSWR from 'swr';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { AlsoSubscription } from 'models/AlsoMarketPlace';

/**
 * Fetch subscriptions from Also.
 * @param alsoConnectionID
 */

export const useAlsoSubscriptions = (alsoConnectionID: string | undefined) => {
  const fetchAlsoSubscriptions = async () => {
    if (!alsoConnectionID) {
      return null;
    }
    try {
      const response = await authorizedFetch<AlsoSubscription[] | null>(
        `${BaseAPIEndpoint}Also/company/${alsoConnectionID}/subscriptions`,
        {
          method: 'GET',
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching SubscriptionConnection object', error);
      throw error;
    }
  };

  return useSWR('alsoSubscriptions', fetchAlsoSubscriptions);
};
