import { FC, useMemo } from 'react';
import { Badge, Button, Icon, Skeleton, Table } from '@intility/bifrost-react';
import TableFeedback from 'components/common/tableFeedback/TableFeedback';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { CopyIconButton } from 'components';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';
import ShowExtendedRowContent from '../extendedContent/ShowExtendedRowContent';
import { AlsoGroupConnection } from 'models/AlsoMarketPlace';

interface ConnectedSubscriptionsProps {
  rawSubscriptionData?: AlsoGroupConnection[] | AlsoGroupSubscription[] | undefined | null;
  isloading?: boolean;
  setShowConnectedManageDrawer: (show: boolean) => void;
  setSelectedSubscription: (config: AlsoGroupSubscription) => void;
  filteredData: AlsoGroupSubscription[];
  handleMarketMutate: () => void;
}

export const ShowConnectedSubscriptions: FC<ConnectedSubscriptionsProps> = ({
  rawSubscriptionData,
  isloading,
  setShowConnectedManageDrawer,
  setSelectedSubscription,
  filteredData,
  handleMarketMutate,
}) => {
  if (!rawSubscriptionData) return null;
  const handleManageClick = (config: AlsoGroupSubscription) => {
    setShowConnectedManageDrawer(true);
    if (config) {
      setSelectedSubscription(config);
    }
  };
  const connectedSubscriptions = useMemo(() => {
    if (filteredData && filteredData.length > 0 && !isloading) {
      return filteredData.map((config) => {
        return (
          <Table.Row
            key={config.id}
            content={<ShowExtendedRowContent config={config} handleMarketMutate={handleMarketMutate} />}
            limitExpandClick
          >
            <Table.Cell>
              <CopyIconButton copyString={config.id} />
              {config.productDisplayName}
            </Table.Cell>
            <Table.Cell>{config.language}</Table.Cell>
            <Table.Cell>{config.subscriptionId ?? 'N/A'}</Table.Cell>
            <Table.Cell>{config.quantity ?? 'N/A'}</Table.Cell>
            <Table.Cell>{config.uniqueGroupMemberCount}</Table.Cell>
            {
              <Table.Cell>
                {config.advancePeriodEndAction === 'Renew' ? (
                  <Badge pill>Yes</Badge>
                ) : (
                  <Badge state="alert" pill>
                    No
                  </Badge>
                )}
              </Table.Cell>
            }
            <Table.Cell align="right">
              <Button small variant="outline" onClick={() => handleManageClick(config)}>
                <Icon icon={faCog}></Icon> Manage
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    if (isloading) {
      return (
        <Skeleton repeat={3}>
          <Table.Row>
            <Skeleton repeat={10}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={9}>
            <TableFeedback
              unfilteredData={rawSubscriptionData}
              emptyMessage={'No connected subscriptions present'}
              filteredData={filteredData}
            />
          </Table.Cell>
        </Table.Row>
      );
    }
  }, [filteredData, isloading]);

  return <>{connectedSubscriptions}</>;
};

export default ShowConnectedSubscriptions;
