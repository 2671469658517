import useSWR from 'swr';
import { Group } from '@microsoft/microsoft-graph-types';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all groups for a customer.
 * @param tenantId
 */

export const useGroups = (tenantId: string | undefined) => {
  const fetchGroups = async () => {
    try {
      const response = await authorizedFetch<Group[]>(`${BaseAPIEndpoint}tenants/${tenantId}/groups`, {
        method: 'GET',
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching tenantGroups object', error);
      throw error;
    }
  };

  return useSWR('tenantGroups', fetchGroups);
};
