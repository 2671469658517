import { Link } from 'react-router-dom';
import styles from './Card.module.css';
import { Card, Icon } from '@intility/bifrost-react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface CardProps {
  link: string;
  title: string;
  content: string;
  icon: IconDefinition;
  styling?: React.CSSProperties;
}

const PageCard = ({ link, title, content, icon, styling }: CardProps) => {
  return (
    <Card className={styles.cardContainer} padding="none">
      <div className={styles.cardLink}>
        <Link to={link} className="bf-neutral-link">
          <div className={styles.cardHeader}>
            <Icon icon={icon} style={styling} />
            <Card.Title className={styles.cardTitle + ' bf-h4'}>{title}</Card.Title>
          </div>
          <Card.Content className={styles.content}>{content}</Card.Content>
        </Link>
      </div>
    </Card>
  );
};

export default PageCard;
