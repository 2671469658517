import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const postHandledAlsoSubscription = async (tenantId: string, subscriptionId: string) => {
  const data = {
    tenantId: tenantId,
    alsoSubscriptionId: subscriptionId,
  };
  if (!tenantId || !subscriptionId) {
    throw new Error('Missing tenantId (' + tenantId + ') or subscriptionId (' + subscriptionId + ')');
  } else {
    try {
      const response = await authorizedFetch(`${BaseAPIEndpoint}Also/handledSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.data) {
        throw new Error('Error posting subscription as handled');
      }
      return response;
    } catch (error) {
      console.log('Error posting subscription as handled', error);
      throw error;
    }
  }
};
