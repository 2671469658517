import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export interface SubscriptionDetails {
  friendlyName?: string | undefined;
  quantity?: number | undefined;
  autoRenewal?: boolean | undefined;
  status?: string | undefined;
}

export const patchSubscriptionDetails = async (
  tenantId: string,
  subscriptionId: string,
  friendlyName?: string | undefined,
  quantity?: number | undefined,
  autoRenewal?: boolean,
  selectedSubscriptionStatus?: string | undefined
) => {
  const data: SubscriptionDetails = {
    friendlyName: friendlyName !== undefined ? friendlyName : undefined,
    quantity: quantity !== undefined ? quantity : undefined,
    autoRenewal: autoRenewal !== undefined ? autoRenewal : undefined,
    status: selectedSubscriptionStatus !== undefined ? selectedSubscriptionStatus : undefined,
  };
  try {
    const response = await authorizedFetch(
      `${BaseAPIEndpoint}Tenants/${tenantId}/Subscriptions/${subscriptionId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    if (!response.data) {
      throw new Error('Error patching subscription details');
    }
    return response;
  } catch (error) {
    console.log('Error patching subscription details', error);
    throw error;
  }
};
