import { useMemo, useState } from 'react';
import { Spinner } from '@intility/bifrost-react';
import AssignmentFilter from './components/assignmentFilter/AssignmentFilter';
import LicenseStateTable from './components/licenseStateTable/LicenseStateTable';
import { useUserLicenseErrors } from '../../helpers/hooks';
import { UserLicenseState } from '../../models';
import { ErrorBoundary } from '../../components';
import styles from './AssignmentErrors.module.css';

const AssignmentErrors = () => {
  const { data: value, isValidating, error } = useUserLicenseErrors();
  const [licenseErrors, setLicenseErrors] = useState<UserLicenseState[]>([]);

  const licenseFilteredOnTime = useMemo(() => {
    return value?.filter((license) => {
      if (license.errorMessage === 'CountViolation') {
        let dateObj = new Date(license.lastUpdatedTime);
        let QUARTER_HOUR = 1000 * 60 * 7;
        let is15MinAgo = Date.now() - QUARTER_HOUR;
        return dateObj.getTime() < is15MinAgo;
      } else {
        return true;
      }
    });
  }, [value]);

  return (
    <div className={styles.page + ' bfl-page-padding'}>
      <h1 style={{ marginTop: 0 }} className="bf-h1">
        Assignment errors
      </h1>
      {!licenseFilteredOnTime ? (
        <>
          <Spinner size={20} label="Getting things ready" />
        </>
      ) : (
        <ErrorBoundary>
          <AssignmentFilter setLicenseErrors={setLicenseErrors} userLicenseStates={licenseFilteredOnTime} />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <LicenseStateTable userLicenseStates={licenseErrors} loading={isValidating} error={error} />
      </ErrorBoundary>
    </div>
  );
};

export default AssignmentErrors;
