import { FC, useState } from 'react';
import { Button, Icon, Inline, Modal } from '@intility/bifrost-react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useRemoveAdobeGroup } from '../../../../../../../../helpers/hooks';
import styles from './RemoveAdobe.module.css';
import { mutate } from 'swr';

interface RemoveModalProps {
  groupId: string;
}

const RemoveAdobe: FC<RemoveModalProps> = ({ groupId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { removeAdobeGroup } = useRemoveAdobeGroup();
  const TIMEOUT_DURATION = 1000;

  const handelRemoveAdobe = () => {
    removeAdobeGroup(groupId).then(() => {
      setModalOpen(false);
      setTimeout(() => {
        mutate('GetAdobe');
      }, TIMEOUT_DURATION);
    });
  };

  return (
    <>
      <Modal
        className={styles.container}
        onRequestClose={() => setModalOpen(false)}
        header="Delete Adobe"
        isOpen={modalOpen}
      >
        <p>This will delete this record.</p>
        <Inline className={styles.buttonGroup}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button state="alert" variant="filled" onClick={handelRemoveAdobe} className={styles.RemoveBtn}>
            Delete
          </Button>
        </Inline>
      </Modal>
      <Button
        state="alert"
        title="Remove connection"
        small
        onClick={() => setModalOpen(true)}
        style={{ border: 'none', color: 'red' }}
      >
        <Icon color="red" icon={faTrash} /> Delete
      </Button>
    </>
  );
};

export default RemoveAdobe;
