import { FC, useState } from 'react';
import { Button, Icon, Modal } from '@intility/bifrost-react';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import styles from './RemoveConnection.module.css';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';
import { useRemoveAlsoGroupConfiguration } from 'helpers/hooks/useRemoveGroupAlsoConfiguration';

interface RemoveModalProps {
  groupId: string;
  AlsoGroupSubscription: AlsoGroupSubscription;
  handleMarketMutate: () => void;
}

const RemoveConnection: FC<RemoveModalProps> = ({ groupId, AlsoGroupSubscription, handleMarketMutate }) => {
  const [showDisconnectGroupModal, setShowDisconnectGroupModal] = useState(false);
  const { removeAlsoGroupConfiguration } = useRemoveAlsoGroupConfiguration();

  const groupName = AlsoGroupSubscription.alsoGroupConnections.find((group) => group.id === groupId);

  const handelRemoveGroupConfig = (event: React.UIEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    removeAlsoGroupConfiguration(groupId, AlsoGroupSubscription).then(() => {
      setShowDisconnectGroupModal(false);
      handleMarketMutate();
    });
  };

  return (
    <>
      <Modal onRequestClose={() => setShowDisconnectGroupModal(false)} isOpen={showDisconnectGroupModal} width={'600px'}>
        <div className={styles.modalContent}>
          <h5 className={styles.headerText + ' bf-h3'}>
            <Icon icon={faLinkSlash} className={styles.headerIcon}></Icon> Disconnect group from subscription
          </h5>
          <div className={styles.modaltextwrapper}>
            <p className={'bf-medium'}>
              Are you sure you want to disconnect <span className={'bf-medium bf-strong'}>{groupName?.groupName} </span>
              <span className={'bf-medium'}>from the </span>
              <span className={'bf-medium bf-strong'}> {AlsoGroupSubscription.productDisplayName} </span>
              <span> subscription?</span>
            </p>
          </div>
          <p className={'bf-medium'}>
            This action will not remove existing subscribers, but it will stop synchronization.
          </p>

          <div className={styles.modalButtonwrapper}>
            <Button variant="filled" state="alert" onClick={handelRemoveGroupConfig}>
              Disconnect
            </Button>
            <Button type="button" onClick={() => setShowDisconnectGroupModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Button
        title="Remove connection"
        small
        variant="outline"
        state="alert"
        onClick={() => setShowDisconnectGroupModal(true)}
      >
        <Icon icon={faLinkSlash}></Icon> Disconnect
      </Button>
    </>
  );
};

export default RemoveConnection;
