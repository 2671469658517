import {
  addMonths,
  isWithinInterval,
  parseISO,
  lastDayOfMonth,
  setHours,
  isSameDay,
  addYears,
  subDays,
} from 'date-fns';

export const datePickerLogic = (availability, CSPSubscription) => {
  const licenseData = CSPSubscription?.map((subscription) => {
    return {
      name: subscription.name,
      commitmentEndDate: subscription.commitmentEndDate,
      quantity: subscription.quantity,
    };
  });
  let dateMap = {};

  licenseData?.forEach((data) => {
    if (dateMap[data.commitmentEndDate]) {
      dateMap[data.commitmentEndDate].amount += data.quantity;
      dateMap[data.commitmentEndDate].license.push({ label: data.name, amount: data.quantity });
    } else {
      dateMap[data.commitmentEndDate] = {
        amount: data.quantity,
        license: [{ label: data.name, amount: data.quantity }],
      };
    }
  });

  let filteredData = Object.keys(dateMap).map((date) => {
    return {
      date: date,
      amount: dateMap[date].amount,
      license: dateMap[date].license,
    };
  });

  // Function to hard format the date to include noon time
  function formatDateWithNoonTime(date) {
    const dateWithNoon = setHours(date, 12);
    const isoString = dateWithNoon.toISOString();
    return isoString.replace(/\.\d{3}/, '');
  }

  let now = new Date();
  now = setHours(now, 12);
  now = new Date(now.toISOString());
  let possibleEndDate;
  let additionalOptions: { date: string }[] = [];

  if (availability) {
    switch (availability.termDuration) {
      case 'P1M':
        // Last day of this month
        possibleEndDate = lastDayOfMonth(now);
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        // In one month
        possibleEndDate = subDays(addMonths(now, 1), 1);
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        break;
      case 'P1Y':
        // Last day of last month but in one year
        possibleEndDate = lastDayOfMonth(addMonths(now, 11));
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        // In one year
        possibleEndDate = subDays(addYears(now, 1), 1);
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        break;
      case 'P3Y':
        //Last day of last month but in three years
        possibleEndDate = lastDayOfMonth(addMonths(now, 35));
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        //In three years
        possibleEndDate = subDays(addYears(now, 3), 1);
        if (!filteredData.some((data) => isSameDay(parseISO(data.date), possibleEndDate))) {
          additionalOptions.push({
            date: formatDateWithNoonTime(possibleEndDate),
          });
        }
        break;
      default:
        return filteredData;
    }
  } else {
    return filteredData;
  }

  let cleansedData = filteredData.filter((data) => {
    let dataDate = parseISO(data.date);

    return isWithinInterval(dataDate, { start: now, end: setHours(possibleEndDate, 12) });
  });

  cleansedData = [
    ...cleansedData,
    ...additionalOptions.map((option) => ({
      date: option.date,
      amount: 0,
      license: [],
    })),
  ];

  return cleansedData;
};
