import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { Theme } from '@intility/bifrost-react';

/*
	As Bifrost is phasing out the useTheme hook along with the theme picker itself, we are implementing a
	custom hook to handle the theme logic. This hook firstly checks if the theme is set via local storage.
	If not, it checks the user's preferred color scheme via media query. If that is not set (it should be
	the case for all modern browsers), it defaults to dark mode.
*/

const THEME_LOCALSTORAGE_KEY = 'bfTheme' as const;

const useMediaQuery = (query: string) => {
  // single instance of mediaQueryList per query
  const mediaQueryList = useMemo(() => (typeof matchMedia === 'undefined' ? undefined : matchMedia(query)), [query]);
  const [match, setMatch] = useState(mediaQueryList?.matches);

  useLayoutEffect(() => {
    if (mediaQueryList !== undefined) {
      // does nothing if state already matches
      // still necessary in case mediaListQuery changes due to query changing
      setMatch(mediaQueryList.matches);
      const listener = (e) => setMatch(e.matches);
      mediaQueryList.addEventListener('change', listener);
      return () => mediaQueryList.removeEventListener('change', listener);
    }
  }, [mediaQueryList]);
  return match;
};

const usePreferredColorScheme = (): Theme => {
  const systemDark = useMediaQuery('(prefers-color-scheme: dark)');
  return systemDark ? 'dark' : 'light';
};

export const useTheme = () => {
  const [localStorageTheme, setLocalStorageTheme] = useLocalStorageState<Theme>(THEME_LOCALSTORAGE_KEY);
  const preferredTheme: Theme = usePreferredColorScheme();

  const setTheme = useCallback(
    (newTheme: Theme) => {
      setLocalStorageTheme(newTheme);
    },
    [setLocalStorageTheme]
  );

  const { theme, currentTheme } = useMemo(() => {
    const theme: Theme = localStorageTheme ?? 'system';

    let currentTheme: Theme;
    if (localStorageTheme === 'dark' || localStorageTheme === 'light') {
      currentTheme = localStorageTheme;
    } else {
      currentTheme = preferredTheme;
    }

    return { theme, currentTheme };
  }, [localStorageTheme, preferredTheme]);

  return { theme, currentTheme, setTheme };
};
