export const TranslateStatuscode = (status) => {
  switch (status) {
    case 0:
      return 'None';
    case 1:
      return 'Active';
    case 2:
      return 'Suspended';
    case 3:
      return 'Deleted';
    case 4:
      return 'Expired';
    case 5:
      return 'Pending';
    case 6:
      return 'Disabled';
    default:
      return 'Unknown';
  }
};
