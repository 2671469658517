import useSWR from 'swr';
import { ExternalSubscriptionInfoDto } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all external Subscription info for a customer.
 * @param tenantId
 */

export const useExternalSubscriptionsInfo = (tenantId: string | undefined) => {
  const fetchExternalSubscriptionInfoDto = async () => {
    try {
      const response = await authorizedFetch<ExternalSubscriptionInfoDto>(
        `${BaseAPIEndpoint}externalSubscriptions/getInformation/${tenantId}`,
        {
          method: 'GET',
        }
      );
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching ExternalSubscriptionInfoDto object', error);
      throw error;
    }
  };

  return useSWR('ExternalSubscriptionInfoDto', fetchExternalSubscriptionInfoDto);
};
