import useSWR from 'swr';
import { Tenant } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all Tenants.
 * @param tenantId
 */

export const useTenants = () => {
  const fetchTenants = async () => {
    try {
      const response = await authorizedFetch<Tenant[]>(`${BaseAPIEndpoint}tenants`, {
        method: 'GET',
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching tenant object', error);
      throw error;
    }
  };

  return useSWR('tenants', fetchTenants);
};
