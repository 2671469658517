import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { CustomerAccount } from 'models/AlsoMarketPlace';

export const fetchAlsoCustomers = async () => {
  try {
    const response = await authorizedFetch<CustomerAccount[] | null>(`${BaseAPIEndpoint}Also/customers/`, {
      method: 'GET',
    });
    if (!response.data) {
      throw new Error('Error fetching products');
    }
    return response;
  } catch (error) {
    console.log('Error fetching products', error);
    throw error;
  }
};
