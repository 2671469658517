import { Badge, Button, Icon, Skeleton, Table } from '@intility/bifrost-react';
import { FC, useMemo } from 'react';
import styles from './ShowExternalSubscriptions.module.css';
import { ExternalSubscriptionDto } from 'models';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import TableFeedback from 'components/common/tableFeedback/TableFeedback';

interface ExternalSubscriptionsProps {
  rawSubscriptionData?: ExternalSubscriptionDto[];
  renderGroupName: any;
  isloading?: boolean;
  error?: any;
  filteredData?: any[];
  setSelectedSubscription: any;
  setShowConnectedManageDrawer: any;
}

export const ShowExternalSubscriptions: FC<ExternalSubscriptionsProps> = ({
  rawSubscriptionData,
  renderGroupName,
  isloading,
  error,
  filteredData,
  setSelectedSubscription,
  setShowConnectedManageDrawer,
}) => {
  const externalSubs = useMemo(() => {
    const handleManageClick = (externalSub: ExternalSubscriptionDto) => {
      setShowConnectedManageDrawer(true);
      if (externalSub) {
        setSelectedSubscription(externalSub);
      }
    };

    const CheckReminder = (reminder: number | null) => {
      if (reminder === null || reminder === 0) {
        return (
          <Badge state="neutral" pill>
            No
          </Badge>
        );
      } else {
        return <Badge pill>Yes</Badge>;
      }
    };

    if (filteredData && filteredData.length > 0 && !isloading) {
      return filteredData.map((externalSub) => {
        return (
          <Table.Row key={externalSub.licenseServiceId}>
            <Table.Cell>{externalSub.skuName}</Table.Cell>
            <Table.Cell className={styles.vendorCell}>{externalSub.vendor.name}</Table.Cell>
            <Table.Cell>{externalSub.agreementType ? externalSub.agreementType : 'N/A'}</Table.Cell>
            <Table.Cell>{externalSub.availableUnits == 1333333337 ? 'N/A' : externalSub.availableUnits}</Table.Cell>
            <Table.Cell>{renderGroupName(externalSub.groupId)}</Table.Cell>
            <Table.Cell>{externalSub.termDuration}</Table.Cell>
            <Table.Cell>
              {new Date(externalSub.renewalDate).toDateString() + ' '}
              {externalSub.autoRenewal ? <Badge pill>Auto</Badge> : ''}
            </Table.Cell>
            <Table.Cell> {CheckReminder(externalSub.reminderQuantity)}</Table.Cell>
            <Table.Cell align="right">
              <Button small variant="outline" onClick={() => handleManageClick(externalSub)}>
                <Icon icon={faCog}></Icon> Manage
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    if (isloading) {
      return (
        <Skeleton repeat={3}>
          <Table.Row>
            <Skeleton repeat={11}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={10}>
            <TableFeedback
              unfilteredData={rawSubscriptionData}
              emptyMessage={'No external subscriptions present'}
              error={error}
              filteredData={filteredData}
            />
          </Table.Cell>
        </Table.Row>
      );
    }
  }, [filteredData, renderGroupName, isloading]);
  return <>{externalSubs}</>;
};

export default ShowExternalSubscriptions;
