import { FC, useState } from 'react';
import { Button, Icon, Modal } from '@intility/bifrost-react';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionConnection } from '../../../../../../../models';
import { useRemoveGroupConfiguration } from '../../../../../../../helpers/hooks';
import styles from './RemoveConnection.module.css';

interface RemoveModalProps {
  groupId: string;
  subscriptionConnection: SubscriptionConnection;
}

const RemoveConnection: FC<RemoveModalProps> = ({ groupId, subscriptionConnection }) => {
  const [showDisconnectGroupModal, setDisconnectGroupModal] = useState(false);
  const { removeGroupConfiguration } = useRemoveGroupConfiguration();

  const groupName = subscriptionConnection.groupConfigurations.find((group) => group.id === groupId);

  const handelRemoveGroupConfig = (event: React.UIEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    removeGroupConfiguration(groupId, subscriptionConnection).then(() => {
      setDisconnectGroupModal(false);
    });
  };

  return (
    <>
      <Modal onRequestClose={() => setDisconnectGroupModal(false)} isOpen={showDisconnectGroupModal} width={'600px'}>
        <div className={styles.modalContent}>
          <h5 className={styles.headerText + ' bf-h3'}>
            <Icon icon={faLinkSlash} className={styles.headerIcon}></Icon> Disconnect group from subscription
          </h5>
          <div className={styles.modaltextwrapper}>
            <p className={'bf-medium'}>
              Are you sure you want to disconnect <span className={'bf-medium bf-strong'}>{groupName?.name} </span>
              <span className={'bf-medium'}>from the </span>
              <span className={'bf-medium bf-strong'}> {subscriptionConnection.name} </span>
              <span> subscription?</span>
            </p>
          </div>
          <p className={'bf-medium'}>
            This action will not remove existing subscribers, but it will stop synchronization.
          </p>

          <div className={styles.modalButtonwrapper}>
            <Button variant="filled" state="alert" onClick={handelRemoveGroupConfig}>
              Disconnect
            </Button>
            <Button type="button" onClick={() => setDisconnectGroupModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Button
        title="Remove connection"
        small
        variant="outline"
        state="alert"
        onClick={() => setDisconnectGroupModal(true)}
      >
        <Icon icon={faLinkSlash}></Icon> Disconnect
      </Button>
    </>
  );
};

export default RemoveConnection;
