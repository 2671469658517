import { createRoot } from 'react-dom/client';
import { SWRConfig } from 'swr';
import App from './app/App';
import Authentication from './auth/Authentication';
import { reactPlugin } from './services/appInsights/appInsights';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

if (import.meta.env.PROD) {
  // Log app version to the console for easy access
  console.log('APP VERSION: ', `${import.meta.env.VITE_VERSION}`);
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <Authentication>
      <SWRConfig>
        <App />
      </SWRConfig>
    </Authentication>
  </AppInsightsContext.Provider>,
);
