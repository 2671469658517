import { FC } from 'react';
import styles from './OrderSubmitPage.module.css';
import { Icon } from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import { Control, Controller, SetFieldValue, WatchInternal } from 'react-hook-form';
import { faFileLines, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { OptionType } from 'models';
import { OrderSku } from 'models/OrderSkus';
import { SegmentOption } from 'models/OrderSegment';

interface OrderSubmitPageOneProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  watch: WatchInternal<any>;
  segmentOptions: SegmentOption[];
  isLicenseLoading: boolean;
  availableLicenses: OptionType[] | undefined;
  isSkuLoading: boolean;
  availableSkus: OrderSku[] | undefined;
}

const OrderSubmitPageOne: FC<OrderSubmitPageOneProps> = ({
  control,
  segmentOptions,
  isLicenseLoading,
  availableLicenses,
  isSkuLoading,
  availableSkus,
  setValue,
  watch,
}) => {
  const quantity = watch('quantity');

  const crementHandler = (type: 'plus' | 'minus') => {
    if (type === 'plus') {
      setValue('quantity', quantity + 1);
    } else if (type === 'minus' && quantity > 1) {
      setValue('quantity', quantity - 1);
    }
  };

  return (
    <div className={styles.pageMainContainer}>
      <div className={styles.formHeader}>
        <div className={styles.iconContainer}>
          <Icon icon={faFileLines} className={styles.headerIcon}></Icon>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.customh5BecauseBFSPadding + ' bf-h5'}>Subscription details</span>
          <span className="bf-medium bf-strong">Step 1 of 3</span>
        </div>
      </div>
      <div className={styles.linees}></div>
      <Controller
        name="segment"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            className={styles.basicSelect}
            placeholder={''}
            label={'Choose segment'}
            value={value}
            options={segmentOptions}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="license"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder={isLicenseLoading ? 'Fetching licenses...' : 'Select license'}
            label={'Choose license'}
            value={value}
            options={availableLicenses}
            onChange={onChange}
            isLoading={isLicenseLoading}
            className={styles.basicSelect}
            isDisabled={isLicenseLoading}
          />
        )}
      />
      <Controller
        name="productSku"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder={isSkuLoading ? 'Fetching products...' : 'Select product'}
            label={'Choose product (Sku name)'}
            value={value}
            options={availableSkus}
            onChange={onChange}
            isLoading={isSkuLoading}
            className={styles.basicSelect}
            isDisabled={isSkuLoading || !watch('license')}
          />
        )}
      />
      <div className={styles.linees}></div>
      <div className={styles.quantityContainerMain}>
        <div className={styles.inputContainer}>
          <label>Quantity</label>
          <div className={styles.inputButtonContainer}>
            <button
              type="button"
              className={quantity > 1 && watch('productSku') ? styles.crementButtons : styles.crementButtonsDisabled}
              onClick={() => crementHandler('minus')}
            >
              <Icon icon={faMinus}></Icon>
            </button>
            <Controller
              name="quantity"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <input
                  name="quantity"
                  placeholder={'Quantity'}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  className={watch('productSku') ? styles.quantityInput : styles.quantityInputDisabled}
                />
              )}
            />
            <button
              type="button"
              className={watch('productSku') ? styles.crementButtons : styles.crementButtonsDisabled}
              onClick={() => crementHandler('plus')}
            >
              <Icon icon={faPlus}></Icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderSubmitPageOne;
