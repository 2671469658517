import { useEffect, useMemo, useState } from 'react';
import { Input } from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import { matchSorter } from 'match-sorter';
import { UserLicenseState } from '../../../../models';
import styles from './AssignmentFilter.module.css';

interface AssignmentFilterProps {
  setLicenseErrors: React.Dispatch<React.SetStateAction<UserLicenseState[]>>;
  userLicenseStates: UserLicenseState[];
}

const AssignmentFilter = ({ setLicenseErrors, userLicenseStates }: AssignmentFilterProps) => {
  const [upn, setUpn] = useState('');
  const [tenantFilter, setTenantFilter] = useState('');
  const [status, setStatus] = useState('');
  const [subscriptionFilter, setSubscriptionFilter] = useState('');

  const tenantOptions = useMemo(() => {
    const uniqueTenants = userLicenseStates.filter(
      (tenantA, i, tenantB) => i === tenantB.findIndex((index) => index.tenantName === tenantA.tenantName)
    );
    return uniqueTenants.map((tenant) => ({ value: tenant.tenantId, label: tenant.tenantName }));
  }, [userLicenseStates]);

  const statusOptions = useMemo(() => {
    const uniqueStatus = userLicenseStates.filter(
      (statusA, i, statusB) => i === statusB.findIndex((index) => index.errorMessage === statusA.errorMessage)
    );
    return uniqueStatus.map((_status) => ({ value: _status.errorMessage, label: _status.errorMessage }));
  }, [userLicenseStates]);

  const subscriptionOptions = useMemo(() => {
    const uniqueSubs = userLicenseStates.filter(
      (subA, i, subB) => i === subB.findIndex((index) => index.skuName === subA.skuName)
    );
    return uniqueSubs.map((subscription) => ({ value: subscription.skuId, label: subscription.skuName }));
  }, [userLicenseStates]);

  const licenseData = useMemo(() => {
    let filteredData: UserLicenseState[] = [];

    if (userLicenseStates.length > 0) {
      filteredData = userLicenseStates.filter((data) => {
        if (tenantFilter) {
          return data.tenantName === tenantFilter;
        } else if (subscriptionFilter) {
          return data.skuId === subscriptionFilter;
        } else if (status) {
          return data.errorMessage === status;
        } else {
          return true;
        }
      });
    }

    if (upn) {
      return matchSorter(filteredData, upn, { keys: ['upn', 'upn'] });
    }

    return filteredData;
  }, [status, subscriptionFilter, tenantFilter, upn, userLicenseStates]);

  useEffect(() => {
    setLicenseErrors(licenseData);
  }, [licenseData, setLicenseErrors]);

  return (
    <section className={styles.container}>
      <div className={styles.inputGroup}>
        <Select
          className={styles.filter}
          placeholder="- Select tenant -"
          isClearable
          label="Tenant"
          options={tenantOptions}
          onChange={(event) => {
            if (event) {
              setTenantFilter(event.label);
            } else {
              setTenantFilter('');
            }
          }}
        />
        <Select
          className={styles.filter}
          placeholder="- Select subscription -"
          isClearable
          options={subscriptionOptions}
          onChange={(event) => {
            if (event) {
              setSubscriptionFilter(event.value);
            } else {
              setSubscriptionFilter('');
            }
          }}
          label="Subscription"
        />
        <Input className={styles.filter} label="UPN" value={upn} onChange={(event) => setUpn(event.target.value)} />
        <Select
          className={styles.filter}
          placeholder="- Select error type -"
          isClearable
          onChange={(event) => {
            if (event) {
              setStatus(event.value);
            } else {
              setStatus('');
            }
          }}
          options={statusOptions}
          label="Status"
        />
      </div>
    </section>
  );
};
export default AssignmentFilter;
