import { matchSorter } from 'match-sorter';

type GenericObject = { [key: string]: any };

function searchFilter(input: GenericObject[], searchParam: string): GenericObject[] {
  const trimmedSearchParam = searchParam.trim();
  if (trimmedSearchParam === '') {
    return input;
  }

  return matchSorter(input, trimmedSearchParam, {
    keys: Object.keys(input[0] || {}).filter((key) => typeof input[0]?.[key] === 'string'),
  });
}
export default searchFilter;
