import { useState } from 'react';
import { orderBy } from 'lodash';

type SortDirection = 'asc' | 'desc' | 'none' | undefined;

type SortingState = {
  key: string | string[];
  direction: SortDirection;
};

interface Data {
  [key: string]: any;
}

interface HeaderCellProps {
  sorting: SortDirection;
  onClick: (() => void) | undefined;
}

export function useSorting<T extends Data>(data: T[]) {
  const [sorting, setSorting] = useState<SortingState>({
    key: '',
    direction: 'none',
  });

  const onSortChange = (key: string | string[]) => {
    setSorting((previousState) => {
      if (Array.isArray(key)) {
        key = key.join('.');
      }
      if (previousState.key === key && previousState.direction === 'asc') {
        return { key, direction: 'desc' };
      } else {
        return { key, direction: 'asc' };
      }
    });
  };

  const getSortDirection = (key: string | string[]): SortDirection => {
    if (Array.isArray(key)) {
      key = key.join('.');
    }
    return sorting.key === key ? sorting.direction : 'none';
  };

  const getHeaderCellProps = (key: string | string[]): HeaderCellProps => {
    return {
      sorting: getSortDirection(key),
      onClick: () => onSortChange(key),
    };
  };

  const sortedData = orderBy(data, sorting.key, sorting.direction === 'asc' ? 'asc' : 'desc');

  return {
    sortedData,
    getHeaderCellProps,
  };
}
