import { FC } from 'react';
import styles from './TableFeedback.module.css';
import { Message } from '@intility/bifrost-react';

interface TableFeedbackProps {
  unfilteredData?: any[];
  filteredData?: any[];
  error?: any;
  errorMessage?: string;
  emptyMessage?: string;
  onRefetch?: any;
}

const TableFeedback: FC<TableFeedbackProps> = ({
  unfilteredData,
  filteredData,
  error,
  errorMessage,
  onRefetch,
  emptyMessage,
}) => {
  const hasError = !!error;

  if (hasError) {
    return (
      <Message
        header={errorMessage || 'An error occurred, please refresh'}
        className={styles.feedbackMessageAlert}
        state="alert"
      />
    );
  }

  if (!unfilteredData || unfilteredData.length === 0) {
    return <Message header={emptyMessage || 'No data available'} className={styles.feedbackMessage} state="neutral" />;
  }

  if (!filteredData || filteredData.length === 0) {
    return (
      <Message header="Nothing currently match your search" className={styles.feedbackMessage} state="neutral" />
    );
  }

  return null;
};

export default TableFeedback;
