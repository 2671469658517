import { faCog, faSignature, faWarning } from '@fortawesome/free-solid-svg-icons';
import styles from './Home.module.css';
import PageCard from './components/card/Card';

const Home = () => (
  <div className={styles.homeContainer + ' bfl-autocol bfl-page-padding'}>
    <PageCard
      link={'/tenants'}
      title={'Tenants'}
      content={'Configure links between Azure AD groups and Partner Center subscriptions.'}
      icon={faCog}
      styling={{ color: 'var(--bfc-base-c-theme)' }}
    />
    <PageCard
      link={'/assignmentErrors'}
      title={'Assignment errors'}
      content={'View, search and filter license assignments.'}
      icon={faWarning}
      styling={{ color: 'var(--bfc-base-c-warning)' }}
    />
    <PageCard
      link={'/user-details'}
      title={'Assigned Licenses'}
      content={'Display information about a users license state.'}
      icon={faSignature}
      styling={{ color: 'var(--bfc-base-c-chill)' }}
    />
  </div>
);

export default Home;
