/**
 * Inspects the term duration and returns a more user-friendly text.
 *
 * @param termDuration
 */
const renderTermDuration = (termDuration: string | null): string => {
  if (termDuration) {
    if (termDuration.endsWith('M')) {
      return 'Monthly';
    } else if (termDuration.includes('3')) {
      return '3 Years';
    } else if (termDuration.includes('2')) {
      return '2 Years';
    } else {
      return '1 Year';
    }
  } else {
    return 'N/A';
  }
};

export default renderTermDuration;
