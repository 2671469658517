import { Badge } from '@intility/bifrost-react';
import styles from './InnerSelectBadge.module.css';
import { CleansedData } from './DateSelect';

export const InnerSelectBadge = (data: CleansedData) => {
  const subscriptionCount = data.license.length;
  if (subscriptionCount === 1) {
    return (
      <Badge pill className={styles.basicBadge}>
        1 subscription
      </Badge>
    );
  } else if (subscriptionCount === 0) {
    return (
      <Badge pill state="neutral" className={styles.greyBadge}>
        unused date
      </Badge>
    );
  }
  return (
    <Badge pill className={styles.basicBadge}>
      {subscriptionCount} subscriptions
    </Badge>
  );
};
