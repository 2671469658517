import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export interface SubscriptionDetails {
  autoRenewal?: boolean | undefined;
  renewalDate?: string | undefined;
  reminderQuantity?: number | undefined;
}

export const patchExternalSubscription = async (licenseServiceId: string, reminderQuantity) => {
  const data: SubscriptionDetails = {
    autoRenewal: undefined,
    renewalDate: undefined,
    reminderQuantity: reminderQuantity !== undefined ? reminderQuantity : 0,
  };
  try {
    const response = await authorizedFetch(`${BaseAPIEndpoint}/ExternalSubscriptions/${licenseServiceId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.data) {
      throw new Error('Error patching subscription details');
    }
    return response;
  } catch (error) {
    console.log('Error patching subscription details', error);
    throw error;
  }
};
