import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

const connectionString = import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRINGS;

const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,

    maxBatchInterval: 0,
    disableFetchTracking: false,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableUnhandledPromiseRejectionTracking: true,

    enableAutoRouteTracking: true,

    extensions: [reactPlugin],

    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (connectionString) {
  appInsights.loadAppInsights();

  const telemetryInitializer = (item: ITelemetryItem) => {
    const appName = 'Frontend';
    if (item.tags) {
      item.tags['ai.cloud.role'] = appName;
    } else {
      item.tags = [{ 'ai.cloud.role': appName }];
    }
  };

  appInsights.addTelemetryInitializer(telemetryInitializer);
}

export {};
