import React, { FC } from 'react';
import styles from './ConfigureMarketplace.module.css';
import ConfigSideNav from '../components/ConfigSideNav';
import ConfigSideDetails from '../components/ConfigSideDetails';
import Connected from './components/connected/Connected';
import UnConnected from './components/unconnected/UnConnected';
import { MarketProps, MSProps } from 'models/TenantConfigProps';

interface ConfigureMarketplaceProps {
  MarketProps: MarketProps;
  MSProps: MSProps;
}

export const ConfigureMarketplace: FC<ConfigureMarketplaceProps> = React.memo(({ MarketProps, MSProps }) => {
  const {
    amountOfConnectedMarketPlace,
    amountOfUnconnectedMarketPlace,
    amountOfHandledUnconnectedALSO,
    amountOfUnhandledUnconnectedALSO,
    alsoConnectedSubscription,
    isLoadingAlsoConnectedSubscription,
    isLoadingAlsoUnconnectedSubscription,
  } = MarketProps;
  const [showUnconnected, setShowUnconnected] = React.useState<boolean>(true);
  const [showConnected, setShowConnected] = React.useState<boolean>(false);

  const handleButtonClick1 = (): void => {
    setShowUnconnected(true);
    setShowConnected(false);
  };
  const handleButtonClick2 = (): void => {
    setShowUnconnected(false);
    setShowConnected(true);
  };

  const identifyAmounts = (alsoConnectedSubscription: any) => {
    const totals = {
      licensesAmount: 0,
      GroupMembers: 0,
    };
    if (!alsoConnectedSubscription) {
      return totals;
    }

    if (Array.isArray(alsoConnectedSubscription)) {
      alsoConnectedSubscription.forEach((subscription) => {
        totals.licensesAmount += subscription.quantity;
        totals.GroupMembers += subscription.alsoGroupConnections.reduce(
          (acc, connection) => acc + connection.groupMemberCount,
          0
        );
      });
    }

    return totals;
  };

  const { licensesAmount, GroupMembers } = identifyAmounts(alsoConnectedSubscription);

  return (
    <div className={styles.tabsPage}>
      <div className={styles.pageContainerLeft}>
        <ConfigSideNav
          options={[
            {
              label: 'Unconnected',
              handler: handleButtonClick1,
              count: amountOfUnconnectedMarketPlace,
              selected: showUnconnected,
              isloading: isLoadingAlsoUnconnectedSubscription,
            },
            {
              label: 'Connected',
              handler: handleButtonClick2,
              count: amountOfConnectedMarketPlace,
              selected: showConnected,
              isloading: isLoadingAlsoConnectedSubscription,
            },
          ]}
        />
        {showUnconnected && (
          <ConfigSideDetails
            howManyOptions={2}
            option1={'Unhandled Subs'}
            option1Value={amountOfUnhandledUnconnectedALSO}
            option2="Handled Subs"
            option2Value={amountOfHandledUnconnectedALSO}
          />
        )}
        {showConnected && (
          <ConfigSideDetails
            howManyOptions={2}
            option1="Group members"
            option1Value={GroupMembers}
            option2="Licenses"
            option2Value={licensesAmount}
          />
        )}
      </div>
      <div className={styles.pageContainerRight}>
        {showUnconnected && <UnConnected MarketProps={MarketProps} />}
        {showConnected && <Connected MarketProps={MarketProps} MSProps={MSProps} />}
      </div>
    </div>
  );
});

export default ConfigureMarketplace;
