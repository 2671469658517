import { OptionType } from '../../../../../../../../models';
import { useReducer } from 'react';

interface State {
  sku: OptionType;
  tenantId: string;
  agreementType: OptionType;
  termDuration: OptionType;
  autoRenewal: boolean;
  renewalDate: Date | null;
  groupId: OptionType;
  vendor: OptionType;
}

export type Action =
  | { type: 'sku'; sku: OptionType }
  | { type: 'tenantId'; tenantId: string }
  | { type: 'agreementType'; agreementType: OptionType }
  | { type: 'termDuration'; termDuration: OptionType }
  | { type: 'autoRenewal'; autoRenewal: boolean }
  | { type: 'renewalDate'; renewalDate: Date | null }
  | { type: 'groupId'; groupId: OptionType }
  | { type: 'vendor'; vendor: OptionType };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'sku':
      return { ...state, sku: action.sku };
    case 'tenantId':
      return { ...state, tenantId: action.tenantId };
    case 'agreementType':
      return { ...state, agreementType: action.agreementType };
    case 'termDuration':
      return { ...state, termDuration: action.termDuration };
    case 'autoRenewal':
      return { ...state, autoRenewal: action.autoRenewal };
    case 'renewalDate':
      return { ...state, renewalDate: action.renewalDate };
    case 'groupId':
      return { ...state, groupId: action.groupId };
    case 'vendor':
      return { ...state, vendor: action.vendor };
    default:
      return state;
  }
}

const useExternalSubscriptionForm = () => {
  const INITIAL_STATE: State = {
    sku: { value: '', label: '' },
    tenantId: '',
    agreementType: { value: '', label: '' },
    termDuration: { value: '', label: '' },
    autoRenewal: true,
    renewalDate: null,
    groupId: { value: '', label: '' },
    vendor: { value: '', label: '' },
  };

  return useReducer(reducer, INITIAL_STATE);
};

export default useExternalSubscriptionForm;
