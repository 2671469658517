import useSWR from 'swr';
import { Subscription } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Fetch all active subscriptions for a customer.
 * @param tenantId
 */

export const useSubscriptions = (tenantId: string | undefined) => {
  const fetchSubscriptions = async () => {
    try {
      const response = await authorizedFetch<Subscription[]>(`${BaseAPIEndpoint}tenants/${tenantId}/Subscriptions`, {
        method: 'GET',
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching subscriptions object', error);
      throw error;
    }
  };

  return useSWR('subscriptions', fetchSubscriptions);
};
