import React, { FC, useEffect, useState } from 'react';
import styles from './UnConnected.module.css';
import SearchBarFilter from '../../../components/SearchBarFilter';
import { Drawer, Table } from '@intility/bifrost-react';
import ShowUnconnectedSubscriptions from './ShowUnconnectedSubscriptions';
import { useSorting } from 'helpers/hooks';
import { Subscription } from 'models';
import ManageSubsDrawer from '../manageSubsForm/ManageSubsFormDrawerContent';
import { MSProps } from 'models/TenantConfigProps';

interface UnConnectedProps {
  MSProps: MSProps;
}

export const UnConnected: FC<UnConnectedProps> = React.memo(({ MSProps }) => {
  const {
    isLoadingUnconnectedSubscriptions,
    error,
    assuredTenantId,
    groups,
    handleMSMutate,
    HandledMicrosoftSubs,
    UnhandledMicrosoftSubs,
    isLoadingMicrosoftHandledUnconnectedSubscriptions,
    ErrorsalsoHandledUncSubs,
  } = MSProps;

  //Passing jsx as a variable to the searchbarfilter component
  const UnhandledNameElement = (
    <>
      <span className={styles.grayName}>Unconnected: </span>
      <span className={styles.defaultName}>Unhandled subscriptions</span>
    </>
  );
  const HandledNameElement = (
    <>
      <span className={styles.grayName}>Unconnected: </span>
      <span className={styles.defaultName}>Handled subscriptions</span>
    </>
  );

  const [showUnconnectedManageDrawer, setShowUnconnectedManageDrawer] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>({});
  const [handledUnconnected, setHandledUnconnected] = useState<Subscription[]>([]);
  const [unhandledUnconnected, setUnhandledUnconnected] = useState<Subscription[]>([]);
  const [filteredUnhandledData, setFilteredUnhandledData] = useState<Subscription[]>([]);
  const [filteredHandledData, setFilteredHandledData] = useState<Subscription[]>([]);

  const { getHeaderCellProps, sortedData: sortedDataUnhandled } = useSorting<Subscription>(filteredUnhandledData || []);
  const { sortedData: sortedDataHandled } = useSorting<Subscription>(filteredHandledData || []);

  useEffect(() => {
    if (UnhandledMicrosoftSubs && HandledMicrosoftSubs) {
      setHandledUnconnected(HandledMicrosoftSubs);
      setUnhandledUnconnected(UnhandledMicrosoftSubs);
    }
  }, [UnhandledMicrosoftSubs, HandledMicrosoftSubs]);

  return (
    <div className={styles.main}>
      <div className={styles.tableMains}>
        <SearchBarFilter
          title={UnhandledNameElement}
          rawSubscriptionData={unhandledUnconnected}
          setFilteredData={setFilteredUnhandledData}
          filteredData={sortedDataUnhandled}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('name')}>
                Subscription
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('unitType')}>
                Unit type
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('termDuration')}>
                Term
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('billingCycle')}>
                Billing term
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('commitmentEndDate')}>
                Renewal date
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('quantity')}>
                Licenses
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowUnconnectedSubscriptions
              rawSubscriptionData={unhandledUnconnected}
              isLoading={isLoadingUnconnectedSubscriptions}
              error={error}
              setShowUnconnectedManageDrawer={setShowUnconnectedManageDrawer}
              setSelectedSubscription={setSelectedSubscription}
              filteredUnhandledData={sortedDataUnhandled}
            />
          </Table.Body>
        </Table>
      </div>
      {/* HANDLED */}
      <div>
        <SearchBarFilter
          title={HandledNameElement}
          setFilteredData={setFilteredHandledData}
          filteredData={sortedDataHandled}
          rawSubscriptionData={handledUnconnected}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Subscription</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Unit type</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Term</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Billing term</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Renewal date</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Quantity</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowUnconnectedSubscriptions
              rawSubscriptionData={handledUnconnected}
              isLoading={isLoadingMicrosoftHandledUnconnectedSubscriptions}
              error={ErrorsalsoHandledUncSubs}
              setShowUnconnectedManageDrawer={setShowUnconnectedManageDrawer}
              setSelectedSubscription={setSelectedSubscription}
              filteredUnhandledData={sortedDataHandled}
            />
          </Table.Body>
        </Table>
      </div>

      <Drawer
        isOpen={showUnconnectedManageDrawer}
        onRequestClose={() => setShowUnconnectedManageDrawer(false)}
        overlay
        header={'Manage subscription'}
        className={styles.drawer}
      >
        <ManageSubsDrawer
          selectedSubscription={selectedSubscription}
          tenantId={assuredTenantId}
          groups={groups}
          setShowManageDrawer={setShowUnconnectedManageDrawer}
          usage={'unconnected'}
          handleMSMutate={handleMSMutate}
          MSProps={MSProps}
        />
      </Drawer>
    </div>
  );
});

export default UnConnected;
