import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function reprocessLicenseCall(userId: string, tenantId: string): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}users/${userId}/tenants/${tenantId}/reprocessLicenseAssignment`, {
    method: 'POST',
  });
}

export const useReprocessLicense = () => {
  const [loading, setLoading] = useState(false);
  const [reprocessError, setReprocessError] = useState(false);

  const reprocessLicense = useCallback(async (userId: string, tenantId: string) => {
    setLoading(true);
    try {
      const response = await reprocessLicenseCall(userId, tenantId);
      if (response.ok) {
        return true;
      } else {
        setReprocessError(true);
      }
    } catch (errorMessage) {
      setReprocessError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    reprocessLicense,
    loading,
    reprocessError,
  };
};
