import { FC, useMemo } from 'react';
import { Badge, Button, Icon, Skeleton, Table } from '@intility/bifrost-react';
import TableFeedback from 'components/common/tableFeedback/TableFeedback';
import SubscriptionCell from '../../../helpers/SubscriptionCell';
import { CopyIconButton } from 'components';
import renderTermDuration from 'helpers/utils/RenderTermDuration';
import { SubscriptionConnection } from 'models';
import formatDate from 'helpers/formatDate/FormatDate';
import ShowExtendedRowContent from '../extendedContent/ShowExtendedRowContent';
import { faCog } from '@fortawesome/free-solid-svg-icons';

interface ConnectedSubscriptionsProps {
  rawSubscriptionData?: SubscriptionConnection[];
  isloading?: boolean;
  error?: any;
  setShowConnectedManageDrawer: any;
  setSelectedSubscription: any;
  filteredData?: any[];
}

export const ShowConnectedSubscriptions: FC<ConnectedSubscriptionsProps> = ({
  rawSubscriptionData,
  isloading,
  error,
  setShowConnectedManageDrawer,
  setSelectedSubscription,
  filteredData,
}) => {
  const handleManageClick = (config: SubscriptionConnection) => {
    setShowConnectedManageDrawer(true);
    if (config) {
      setSelectedSubscription(config);
    }
  };

  const connectedSubscriptions = useMemo(() => {
    if (filteredData && filteredData.length > 0 && !isloading) {
      return filteredData.map((config) => {
        return (
          <Table.Row
            key={config.licenseServiceId}
            content={<ShowExtendedRowContent config={config} />}
            limitExpandClick
          >
            <Table.Cell>
              <CopyIconButton copyString={config.subscriptionId} />

              <SubscriptionCell
                name={config.name}
                productType={config.productType}
                vendorName={config.vendorName}
                licenseServiceId={config.licenseServiceId}
                partnerStatus={config.partnerStatus}
              />
            </Table.Cell>
            <Table.Cell>{renderTermDuration(config.termDuration)}</Table.Cell>
            <Table.Cell>{config.billingCycle}</Table.Cell>
            <Table.Cell>
              {formatDate(config.commitmentEndDate)} {config.autoRenewEnabled ? <Badge pill>auto</Badge> : ''}
            </Table.Cell>
            <Table.Cell>{config.quantity}</Table.Cell>
            <Table.Cell>{config.assigned}</Table.Cell>
            <Table.Cell align="right">
              <Button small variant="outline" onClick={() => handleManageClick(config)}>
                <Icon icon={faCog}></Icon> Manage
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }
    if (isloading) {
      return (
        <Skeleton repeat={3}>
          <Table.Row>
            <Skeleton repeat={7}>
              <Table.Cell>
                <Skeleton.Text />
              </Table.Cell>
            </Skeleton>
          </Table.Row>
        </Skeleton>
      );
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={9}>
            <TableFeedback
              unfilteredData={rawSubscriptionData}
              emptyMessage={'No connected subscriptions present'}
              error={error}
              filteredData={filteredData}
            />
          </Table.Cell>
        </Table.Row>
      );
    }
  }, [filteredData, isloading]);

  return <>{connectedSubscriptions}</>;
};

export default ShowConnectedSubscriptions;
