import { FC } from 'react';
import { Badge } from '@intility/bifrost-react';
import styles from './ConfigSideDetails.module.css';

interface Props {
  howManyOptions: number;
  option1: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option1Value?: string | number;
  option2Value?: string | number;
  option3Value?: string | number;
  option4Value?: string | number;
  option5Value?: string | number;
}

const ConfigSideDetails: FC<Props> = (props) => {
  const {
    howManyOptions,
    option1,
    option2,
    option3,
    option4,
    option5,
    option1Value,
    option2Value,
    option3Value,
    option4Value,
    option5Value,
  } = props;

  return (
    <div className={styles.navContainer}>
      <span className={styles.configDeets + ' bf-medium'}>
        {option1}
        <Badge className={styles.badgeStyle + ' bf-medium bf-strong'} pill>
          {option1Value}
        </Badge>
      </span>
      {howManyOptions > 1 && (
        <span className={styles.configDeets + ' bf-medium'}>
          {option2}
          <Badge className={styles.badgeStyle + ' bf-medium bf-strong'} pill>
            {option2Value}
          </Badge>
        </span>
      )}
      {howManyOptions > 2 && (
        <span className={styles.configDeets + ' bf-medium'}>
          {option3}
          <Badge className={styles.badgeStyle + ' bf-medium bf-strong'} pill>
            {option3Value}
          </Badge>
        </span>
      )}
      {howManyOptions > 3 && (
        <span className={styles.configDeets + ' bf-medium'}>
          {option4}
          <Badge className={styles.badgeStyle + ' bf-medium bf-strong'} pill>
            {option4Value}
          </Badge>
        </span>
      )}
      {howManyOptions > 4 && (
        <span className={styles.configDeets + ' bf-medium'}>
          {option5}
          <Badge className={styles.badgeStyle + ' bf-medium bf-strong'} pill>
            {option5Value}
          </Badge>
        </span>
      )}
    </div>
  );
};

export default ConfigSideDetails;
