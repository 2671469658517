import { FC, useEffect, useState } from 'react';
import styles from './ConnectMPModal.module.css';
import { Button, Modal } from '@intility/bifrost-react';
import { fetchAlsoCustomers } from 'api/fetchers/fetchAlsoCustomers';
import { CustomerAccount } from 'models/AlsoMarketPlace';
import { APIResponse } from 'auth/authorizedFetch';
import Select from '@intility/bifrost-react-select';
import { connectAlsoCustomer } from 'api/fetchers/connectAlsoCustomer';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';

interface Props {
  showConnectedMarketplaceModal: boolean;
  setShowConnectedMarketplaceModal: (showConnectMarketplace: boolean) => void;
  msTenantName: string | undefined;
  msTenantID: string | undefined;
  alsoConnectionLoading: boolean;
  setAlsoConnectionLoading: (alsoConnectionLoading: boolean) => void;
}

const ConnectMPModal: FC<Props> = ({
  showConnectedMarketplaceModal,
  setShowConnectedMarketplaceModal,
  msTenantName,
  msTenantID,
  alsoConnectionLoading,
  setAlsoConnectionLoading,
}) => {
  const [isSelectedValue, setIsSelectedValue] = useState<any>();
  const [connectedCompanies, setConnectedCompanies] = useState<CustomerAccount[]>([]);

  const TIMEOUT_DURATION = 1000;

  const handleReloadClick = () => {
    window.location.reload();
  };

  const handleCancelClick = () => {
    setShowConnectedMarketplaceModal(false);
    setIsSelectedValue(undefined);
  };

  const handleConnectClick = async () => {
    if (!isSelectedValue) return;
    setAlsoConnectionLoading(true);

    try {
      const alsoCompanyAccountId = isSelectedValue?.value;
      const alsoCustomerName = isSelectedValue?.label;
      await connectAlsoCustomer(alsoCompanyAccountId, msTenantID, alsoCustomerName, msTenantName);
    } catch (error) {
      console.error('Failed to connect:', error);
    } finally {
      setTimeout(() => {
        setShowConnectedMarketplaceModal(false);
        setAlsoConnectionLoading(false);
      }, TIMEOUT_DURATION);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await handleConnectClick();
    handleReloadClick();
  };

  useEffect(() => {
    const fetchData = async () => {
      setAlsoConnectionLoading(true);
      try {
        const response: APIResponse<CustomerAccount[] | null> = await fetchAlsoCustomers();
        if (response.data) {
          setConnectedCompanies(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch companies', error);
      } finally {
        setAlsoConnectionLoading(false);
      }
    };

    if (showConnectedMarketplaceModal) {
      fetchData();
    }
  }, [showConnectedMarketplaceModal, setAlsoConnectionLoading]);

  return (
    <Modal
      className={styles.modal}
      onRequestClose={handleCancelClick}
      isOpen={showConnectedMarketplaceModal}
      header="Connect to marketplace"
    >
      <form onSubmit={handleSubmit}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalDescSelect}>
            <p>Please choose which customer you are connecting</p>
            <Select
              label=""
              placeholder="Search for customer"
              options={connectedCompanies.map((company) => ({
                label: company.alsoCustomerName,
                value: company.alsoCompanyAccountId,
              }))}
              value={isSelectedValue}
              onChange={(value) => setIsSelectedValue(value || undefined)}
              isLoading={alsoConnectionLoading}
            />
          </div>
          <div className={styles.modalButtons}>
            <Button type="button" onClick={handleCancelClick}>
              Cancel
            </Button>
            <SpinnerButton isSubmitting={alsoConnectionLoading} buttonText="Connect" />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ConnectMPModal;
