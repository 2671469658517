import { useEffect, useState } from 'react';

interface IPagination<T> {
	data: Array<T>;
	pageSize?: number;
	currentPage: number;
	numberOfPages: number;
	paginate: (nextPage: number) => void;
}

export function usePagination<T>(data: T[] = [], pageSize = 8): IPagination<T> {
	const [currentPage, setCurrentPage] = useState(1);
	const numberOfPages = Math.ceil(data.length / pageSize);

	useEffect(() => {
		if (data.length > 0 && currentPage > numberOfPages) {
			setCurrentPage(numberOfPages);
		}
	}, [data, numberOfPages, currentPage]);

	const paginate = (nextPage: number): void => setCurrentPage(nextPage);

	return {
		data: data.slice((currentPage - 1) * pageSize, currentPage * pageSize),
		currentPage,
		numberOfPages,
		paginate,
	};
}
