import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, useApplyTheme, Nav } from '@intility/bifrost-react';
import { useTheme } from 'helpers/utils/useTheme';

export function ThemePicker() {
  const { theme, setTheme } = useTheme();
  useApplyTheme(theme);

  return (
    <Nav.Group icon={faCircleHalfStroke}>
      <section>
        <Nav.Header>Color theme</Nav.Header>
        <Checkbox
          type="radio"
          label="Dark"
          name="color-theme"
          checked={theme === 'dark'}
          onChange={() => setTheme('dark')}
        />
        <Checkbox
          type="radio"
          label="Light"
          name="color-theme"
          checked={theme === 'light'}
          onChange={() => setTheme('light')}
        />
        <Checkbox
          type="radio"
          label="System"
          name="color-theme"
          checked={theme === 'system'}
          onChange={() => setTheme('system')}
        />
      </section>
    </Nav.Group>
  );
}
