import React, { FC, useEffect, useState } from 'react';
import styles from './TenantView.module.css';
import { Tabs } from '@intility/bifrost-react';
import ConfigureMicrosoft from './microsoft/ConfigureMicrosoft';
import ConfigureMarketplace from './marketplace/ConfigureMarketplace';
import ConnectMarketplace from './marketplace/ConnectMarketplace';
import { MarketProps, MSProps } from 'models/TenantConfigProps';

interface TenantViewProps {
  setShowConnectedMarketplaceModal: (showConnectMarketplace: boolean) => void;
  MSProps: MSProps;
  MarketProps: MarketProps;
}

export const TenantView: FC<TenantViewProps> = ({ setShowConnectedMarketplaceModal, MSProps, MarketProps }) => {
  const [activeTab, setActiveTab] = useState(0); // 0 for Microsoft, 1 for Marketplace

  const {
    externalSubscriptions,
    subscriptionConnections,
    MirosoftNumber,
    setMirosoftNumber,
    setAmountOfConnectedMS,
    amountOfUnhandledUnconnectedMS,
    setAmountOfUnhandledUnconnectedMS,
    setAmountOfHandledUnconnectedMS,
    setAmountOfDocumentedMS,
    HandledMicrosoftSubs,
    UnhandledMicrosoftSubs,
  } = MSProps;

  const {
    //Marketplace
    alsoConnectionID,
    alsoConnectionLoading,
    alsoUnconnectedSubscription,
    alsoConnectedSubscription,
    MarketplaceNumber,
    setMarketplaceNumber,
    setAmountOfConnectedMarketPlace,
    setAmountOfUnconnectedMarketPlace,
    setAmountOfHandledUnconnectedALSO,
    amountOfUnhandledUnconnectedALSO,
    setAmountOfUnhandledUnconnectedALSO,
    HandledAlsoSubs,
    UnhandledAlsoSubs,
  } = MarketProps;

  useEffect(() => {
    if (subscriptionConnections) {
      setAmountOfConnectedMS(subscriptionConnections.length);
    }
    if (UnhandledMicrosoftSubs) {
      setAmountOfUnhandledUnconnectedMS(UnhandledMicrosoftSubs.length);
    }
    if (HandledMicrosoftSubs) {
      setAmountOfHandledUnconnectedMS(HandledMicrosoftSubs.length);
    }
    if (externalSubscriptions) {
      setAmountOfDocumentedMS(externalSubscriptions.length);
    }
  }, [subscriptionConnections, externalSubscriptions, UnhandledMicrosoftSubs, HandledMicrosoftSubs]);

  useEffect(() => {
    if (alsoConnectedSubscription) {
      setAmountOfConnectedMarketPlace(alsoConnectedSubscription.length);
    }
    if (alsoUnconnectedSubscription) {
      setAmountOfUnhandledUnconnectedALSO(alsoUnconnectedSubscription.length);
    }
    if (HandledAlsoSubs) {
      setAmountOfHandledUnconnectedALSO(HandledAlsoSubs.length);
    }
  }, [alsoConnectedSubscription, alsoUnconnectedSubscription, HandledAlsoSubs]);

  useEffect(() => {
    setMirosoftNumber(amountOfUnhandledUnconnectedMS);
  }, [amountOfUnhandledUnconnectedMS]);

  useEffect(() => {
    setMarketplaceNumber(amountOfUnhandledUnconnectedALSO);
  }, [amountOfUnhandledUnconnectedALSO]);

  useEffect(() => {
    setAmountOfUnconnectedMarketPlace(UnhandledAlsoSubs?.length || 0);
  }, [alsoUnconnectedSubscription, UnhandledAlsoSubs]);

  useEffect(() => {
    setAmountOfConnectedMarketPlace(alsoConnectedSubscription?.length || 0);
  }, [alsoConnectedSubscription]);

  useEffect(() => {
    if (!alsoConnectionID) {
      setMarketplaceNumber(0);
    }
  }, [alsoConnectionID]);

  return (
    <Tabs variant="styled" className={styles.tabStyle}>
      <Tabs.Item
        active={activeTab === 0}
        onClick={() => setActiveTab(0)}
        style={{ background: activeTab === 0 ? 'var(--bfc-base-2)' : 'transparent' }}
        className={`${styles.tabItemStyle} ${activeTab === 0 ? styles.activeTab : ''}`}
        noPadding
        content={
          <div className={styles.tabsContent}>
            <ConfigureMicrosoft MSProps={MSProps} />
          </div>
        }
      >
        <span>
          Microsoft (<span className={styles.numberStyle}>{MirosoftNumber}</span>)
        </span>
      </Tabs.Item>
      <Tabs.Item
        active={activeTab === 1}
        onClick={() => setActiveTab(1)}
        style={{ background: activeTab === 1 ? 'var(--bfc-base-2)' : 'transparent' }}
        className={`${styles.tabItemStyle} ${activeTab === 1 ? styles.activeTab : ''}`}
        noPadding
        content={
          <div className={styles.tabsContent}>
            {alsoConnectionID && <ConfigureMarketplace MarketProps={MarketProps} MSProps={MSProps} />}
            {!alsoConnectionID && !alsoConnectionLoading && (
              <ConnectMarketplace setShowConnectedMarketplaceModal={setShowConnectedMarketplaceModal} />
            )}
          </div>
        }
      >
        <span>
          Marketplace (<span className={styles.numberStyle}>{MarketplaceNumber}</span>)
        </span>
      </Tabs.Item>
    </Tabs>
  );
};

export default TenantView;
