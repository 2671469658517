import { Button, Spinner } from '@intility/bifrost-react';
import { FC } from 'react';
import styles from './SpinnerButton.module.css';

interface SpinnerButtonProps {
  isSubmitting: boolean;
  buttonText: string;
  isDisabled?: boolean;
  state?: 'default' | 'inactive' | 'alert';
}

const SpinnerButton: FC<SpinnerButtonProps> = ({
  isSubmitting,
  buttonText,
  isDisabled,
  state,
}: SpinnerButtonProps) => {
  const combinedState = isDisabled ? 'inactive' : state ? state : 'default';

  return (
    <Button
      className={isDisabled ? styles.submitButtonDisabled : styles.submitButton}
      variant={isSubmitting || isDisabled ? 'outline' : 'filled'}
      type="submit"
      aria-disabled={isSubmitting || isDisabled}
      state={combinedState}
    >
      <span className={isSubmitting ? styles.visibilityHidden : ''}>{buttonText}</span>
      {isSubmitting && <Spinner className={styles.spinner} size={10} />}
    </Button>
  );
};

export default SpinnerButton;
