import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function vendorNameCall(vendor: string): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}Vendor/vendor/${vendor}`, {
    method: 'POST',
  });
}

export const useVendorName = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const saveVendorName = useCallback(async (vendor: string): Promise<void | any> => {
    try {
      setLoading(true);
      setError(false);
      const response = await vendorNameCall(vendor);
      if (response) {
        setLoading(false);
        setError(false);
        return response.data;
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (errorMessage) {
      setLoading(false);
      setError(true);
    }
  }, []);

  return {
    error,
    loading,
    saveVendorName,
  };
};
