import { Button } from "@intility/bifrost-react";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useReprocessLicense } from "../../../../helpers/hooks";
import { FC } from "react";

interface ReprocessLicenseProps {
  userId: string;
  tenantId: string;
}

const ReprocessLicense: FC<ReprocessLicenseProps> = ({ userId, tenantId }) => {
  const { reprocessLicense, loading } = useReprocessLicense();

  const handleReprocessLicense = () => {
    reprocessLicense(userId, tenantId);
  }

  return (
    <>
      <Button
        disabled={loading}
        onClick={handleReprocessLicense}
        small
        icon={faSync}
      >{loading ? "Loading" : "Reprocess"}
      </Button>
    </>
  )
}

export default ReprocessLicense;
