/**
 * Function that takes in date, locale and includeTime.
 * and outputs the date and time with toLocaleDateString.
 * @param date - The date to format
 * @param locale - The locale to use 'nb-NO', 'en-GB', 'en-US' etc.
 * @param includeTime - Whether to include the time in the output
 * @returns A formatted date string
 */
function formatDate(date: string, locale?: string, includeTime?: boolean): string {
  includeTime ?? (includeTime = false);
  locale ?? (locale = 'en-GB');
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  if (includeTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }

  const dateToFormat = new Date(date);
  return dateToFormat.toLocaleDateString(locale, options);
}

export default formatDate;
