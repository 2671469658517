import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import useSWR from 'swr';
import { AdobeGroup } from 'models';

/**
 * Fetch all AdobeGroups for a customer.
 * @param tenantId
 */

export const useGetAdobe = (tenantId: string | undefined) => {
  const fetchGetAdobe = async () => {
    try {
      const response = await authorizedFetch<AdobeGroup[]>(`${BaseAPIEndpoint}Adobe/${tenantId}`, {
        method: 'GET',
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching GetAdobe object', error);
      throw error;
    }
  };

  return useSWR('GetAdobe', fetchGetAdobe);
};
