//Subscriptions
export enum PartnerSubscriptionStatus {
  None,
  Active,
  Suspended,
  Deleted,
  Expired,
  Pending,
  Disabled,
}

export interface Subscription {
  licenseServiceId: string;
  subscriptionId: string;
  name: string;
  quantity: number;
  pendingQuantity: number;
  productType: string | null;
  termDuration: string | null;
  tenantId: string;
  vendorId: string;
  vendorName: string;
  partnerStatus: PartnerSubscriptionStatus;
  unitType: any;
  disabled: boolean;
  billingCycle: string;
  commitmentEndDate: string;
  id: string;
  displayName: string;
  autoRenewEnabled: boolean;
}
