import { FC } from 'react';
import { Badge, Icon, Tooltip } from '@intility/bifrost-react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { PartnerSubscriptionStatus } from '../../../../../models';

interface SubscriptionCellProps {
  name: string;
  productType: string | null;
  vendorName?: string;
  licenseServiceId: string;
  partnerStatus: PartnerSubscriptionStatus;
}

const SubscriptionCell: FC<SubscriptionCellProps> = ({
  name,
  productType,
  vendorName,
  licenseServiceId,
  partnerStatus,
}) => {
  const showNCETag = () => {
    if (productType) {
      if (productType.endsWith('NCE')) {
        return (
          <>
            {name}{' '}
            <Badge pill state="default">
              NCE
            </Badge>
          </>
        );
      } else {
        return <>{name}</>;
      }
    } else {
      return <>{name}</>;
    }
  };

  if (!vendorName) {
    return (
      <>
        <Icon icon={faExclamationTriangle} color="var(--bfc-warning)" /> Vendor information not available
      </>
    );
  }

  if (partnerStatus !== PartnerSubscriptionStatus.Active) {
    return (
      <>
        <Tooltip content={PartnerSubscriptionStatus[partnerStatus]} placement="right">
          <Icon icon={faExclamationTriangle} color="var(--bfc-warning)" />
        </Tooltip>{' '}
        {name || licenseServiceId}
      </>
    );
  }

  return <>{showNCETag() || licenseServiceId}</>;
};
export default SubscriptionCell;
