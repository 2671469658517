import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

export const connectAlsoCustomer = async (alsoCompanyAccountId, msTenantID, alsoCustomerName, msTenantName) => {
  console.log('Connecting to marketplace', alsoCompanyAccountId, msTenantID, alsoCustomerName, msTenantName);

  const data = {
    alsoCompanyAccountId: alsoCompanyAccountId,
    microsoftTenantId: msTenantID,
    alsoCustomerName: alsoCustomerName,
    microsoftTenantName: msTenantName,
  };

  try {
    const response = await authorizedFetch(`${BaseAPIEndpoint}Also/connectCustomer/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.data) {
      throw new Error('Error posting customer');
    }
    return response;
  } catch (error) {
    console.log('Error posting customer', error);
    throw error;
  }
};
