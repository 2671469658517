import { FC, useMemo } from 'react';
import { SubscriptionConnection } from 'models';
import { Table } from '@intility/bifrost-react';
import RemoveConnection from '../removeConnection/RemoveConnection';

interface ShowExtendedRowContentProps {
  config: SubscriptionConnection;
}

export const ShowExtendedRowContent: FC<ShowExtendedRowContentProps> = ({ config }) => {
  const content = useMemo(() => {
    if (config.groupConfigurations.length > 0) {
      const totalMembers = config.groupConfigurations.reduce((acc, group) => acc + group.memberCount, 0);

      return (
        <Table noBorder style={{ background: 'var(--bfc-base-1)' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell align="left" style={{ color: 'var(--bfc-base-c-1)', paddingLeft: '32px' }}>
                Azure AD Groups
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Order Method</Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }}>Members</Table.HeaderCell>
              <Table.HeaderCell align="right"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {config.groupConfigurations.map((group) => (
              <Table.Row key={group.id}>
                <Table.Cell>{group.name}</Table.Cell>
                <Table.Cell>{group.orderMethod}</Table.Cell>
                <Table.Cell>{group.memberCount}</Table.Cell>
                <Table.Cell align="right">
                  {config.vendorName !== 'Intility AS' ? (
                    <RemoveConnection groupId={group.id} subscriptionConnection={config} />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell>
                <span>Total</span>
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <span>{totalMembers}</span>
              </Table.Cell>
              <Table.Cell></Table.Cell> {/* MUST exist */}
            </Table.Row>
          </Table.Body>
        </Table>
      );
    }
    return null;
  }, [config]);

  return content;
};

export default ShowExtendedRowContent;
