import useSWR from 'swr';
import { UserLicenseState } from '../../models';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

/**
 * Hook for fetching users with license errors.
 */

export const useUserLicenseErrors = () => {
  const fetchUserLicenseErrors = async () => {
    try {
      const response = await authorizedFetch<UserLicenseState[]>(`${BaseAPIEndpoint}tenants/LicenseErrors`, {
        method: 'GET',
      });
      if (response.data) {
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching object', error);
      throw error;
    }
  };

  return useSWR('UserLicenseErrors', fetchUserLicenseErrors);
};
