import { FC, useState, useEffect } from 'react';
import styles from './SearchBarFilter.module.css';
import { Input } from '@intility/bifrost-react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import searchFilter from '../helpers/SearchFilter';
import { AlsoGroupConnection } from 'models/AlsoMarketPlace';
import { ExternalSubscriptionDto, Subscription, SubscriptionConnection } from 'models';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';

interface Props {
  title: any;
  rawSubscriptionData:
    | Subscription[]
    | SubscriptionConnection[]
    | ExternalSubscriptionDto[]
    | AlsoGroupSubscription[]
    | AlsoGroupConnection[]
    | undefined
    | null;
  setFilteredData: (filteredData: any[]) => void;
  content?: React.ReactNode;
  filteredData: AlsoGroupConnection[];
}

const SearchBarFilter: FC<Props> = ({
  title,
  rawSubscriptionData,
  setFilteredData,
  content: headerContent,
  filteredData,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!rawSubscriptionData) return;
    try {
      let tempfilterdata = searchFilter(rawSubscriptionData, searchTerm);
      setFilteredData(tempfilterdata);
    } catch (error) {
      console.error('Error filtering data:', error);
      setFilteredData([]);
    }
  }, [searchTerm, rawSubscriptionData, filteredData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={styles.searchHeader}>
      <div className={styles.headerDeets}>
        <h4 className="bf-h4" style={{ marginTop: '0' }}>
          {title}
        </h4>
        {headerContent}
      </div>
      <div className={styles.searchBarContainer}>
        <Input
          type="text"
          placeholder="Search"
          label="search"
          hideLabel
          clearable
          icon={faSearch}
          rightIcon
          className={styles.searchBar}
          onChange={handleSearchChange}
          style={{ margin: '0', paddingLeft: '0', border: 'none' }}
        />
      </div>
    </div>
  );
};

export default SearchBarFilter;
