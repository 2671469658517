import React, { FC, useEffect, useMemo, useState } from 'react';
import { Accordion, Badge, Button, Checkbox, Message, useFloatingMessage } from '@intility/bifrost-react';
import styles from './ManageMarketDrawer.module.css';
import { OptionType } from 'models';
import Select from '@intility/bifrost-react-select';
import { Group } from '@microsoft/microsoft-graph-types';
import SpinnerButton from 'components/common/spinnerButton/SpinnerButton';
import { usePostAlsoGroupConfig } from 'helpers/hooks/usePostAlsoGroupConfig';
import { AlsoSubscription } from 'models/AlsoMarketPlace';
import { AlsoGroupSubscription } from 'models/alsoConnectedSubs';
import { postHandledAlsoSubscription } from 'api/fetchers/postHandledAlsoSubscription';
import { deleteHandledAlsoSubscription } from 'api/fetchers/deleteHandledAlsoSubscription';

interface ManageDrawerFormProps {
  selectedMarketSubscription: AlsoSubscription | AlsoGroupSubscription;
  tenantId: string;
  groups: Group[];
  setShowManageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  usage: 'connected' | 'unconnected';
  handleMarketMutate: () => void;
}

// Type Guard Functions
const isAlsoSubscription = (
  subscription: AlsoSubscription | AlsoGroupSubscription
): subscription is AlsoSubscription => {
  return (subscription as AlsoSubscription).serviceDisplayName !== undefined;
};

const ManageMarketDrawer: FC<ManageDrawerFormProps> = React.memo(
  ({ selectedMarketSubscription, tenantId, groups, setShowManageDrawer, usage, handleMarketMutate }) => {
    const [groupError, setGroupError] = useState<boolean>(false);
    const [group, setGroup] = useState<OptionType | null>(null); 
    const { loading, createAlsoGroupConfig } = usePostAlsoGroupConfig();
    const [connectSubscriptionChanged, setConnectSubscriptionChanged] = useState(false);
    const [handledChanged, setHandledChanged] = useState<boolean>(false);
    const [handled, setHandled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showFloatingMessage } = useFloatingMessage();

    const showMessage = (
      message: string,
      state: 'default' | 'success' | 'warning' | 'alert' | 'neutral' | undefined
    ) => {
      showFloatingMessage(message, { state: state });
    };

    useEffect(() => {
      if (selectedMarketSubscription && selectedMarketSubscription.handledStatus === 'handled') {
        setHandled(true);
      } else {
        setHandled(false);
      }
    }, [selectedMarketSubscription]);

    const AlsoSub: AlsoSubscription = selectedMarketSubscription as AlsoSubscription;

    const handleCancel = () => {
      setGroup(null);
      setShowManageDrawer(false);
      setIsLoading(false);
    };

    const groupOptions = useMemo(() => {
      return groups.map((groupItem) => ({
        value: groupItem.id || '',
        label: groupItem.displayName || '',
      }));
    }, [groups]);

    const handleFormSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (connectSubscriptionChanged && group && selectedMarketSubscription) {
        setIsLoading(true);
        try {
          let groupAlsoConfig;
          if (isAlsoSubscription(selectedMarketSubscription)) {
            groupAlsoConfig = await createAlsoGroupConfig(
              tenantId,
              group.value,
              group.label,
              selectedMarketSubscription.accountId,
              selectedMarketSubscription.serviceDisplayName
            );
          } else {
            groupAlsoConfig = await createAlsoGroupConfig(
              tenantId,
              group.value,
              group.label,
              selectedMarketSubscription.subscriptionId,
              selectedMarketSubscription.productDisplayName
            );
          }
          if (groupAlsoConfig) {
            setGroup(null);
            setShowManageDrawer(false);
            setIsLoading(false);
            showMessage('Subscription connected to group', 'success');
          }
        } catch (error) {
          console.log(error);
          showMessage(`Failed to connect subscription: ${JSON.stringify(error)}`, 'alert');
        }
      }

      if (handledChanged && AlsoSub) {
        if (handled) {
          setIsLoading(true);
          try {
            const config = await postHandledAlsoSubscription(tenantId, AlsoSub.accountId);
            if (config) {
              setShowManageDrawer(false);
              setIsLoading(false);
              showMessage('Subscription marked as handled', 'success');
            }
          } catch (error) {
            console.log(error);
            showMessage(`Failed to set subscription as "handled": ${JSON.stringify(error)}`, 'alert');
          }
        }
        if (!handled) {
          setIsLoading(true);
          try {
            const config = await deleteHandledAlsoSubscription(tenantId, AlsoSub.handledId);
            if (config) {
              setShowManageDrawer(false);
              setIsLoading(false);
              showMessage('Subscription marked as unhandled', 'success');
            }
          } catch (error) {
            console.log(error);
            showMessage(`Failed to set subscription as "unHandled": ${JSON.stringify(error)}`, 'alert');
          }
        }
        setIsLoading(false);
        handleMarketMutate();
      }

      setConnectSubscriptionChanged(false);
      setHandledChanged(false);
      handleMarketMutate();
    };

    return (
      <form onSubmit={handleFormSubmit}>
        <div className={styles.main}>
          <div className={styles.headerItems}>
            <p className={styles.selectedSubscrip + ' bf-medium'}>
              {isAlsoSubscription(selectedMarketSubscription)
                ? selectedMarketSubscription.serviceDisplayName
                : selectedMarketSubscription.productDisplayName}
            </p>
            {AlsoSub.advancePeriodEndAction === 'Renew' && (
              <Message>Status: Auto {AlsoSub.advancePeriodEndAction}</Message>
            )}
          </div>

          <Accordion variant="styled">
            {(usage === 'connected' || usage === 'unconnected') && (
              <>
                <Accordion.Item
                  title={<span className={'bf-strong bf-large'}>Connect subscription </span>}
                  defaultActive
                  disabled={loading || isLoading}
                >
                  <Select
                    value={group}
                    isClearable
                    label="Azure AD group"
                    placeholder="- Select group -"
                    onBlur={() => setGroupError(false)}
                    state={groupError ? 'alert' : 'default'}
                    options={groupOptions}
                    onChange={(groupOption) => {
                      if (groupOption === null) {
                        setGroup(null);
                      } else {
                        setGroup(groupOption);
                      }
                      setConnectSubscriptionChanged(true);
                    }}
                  />
                  <Badge style={{ marginTop: 16, marginBottom: 8 }} state="neutral">
                    Check that the same group is connected in the Adobe Admin Portal{' '}
                  </Badge>
                </Accordion.Item>
              </>
            )}

            {usage === 'unconnected' && (
              <Accordion.Item
                title={<span className={'bf-strong bf-large'}>Mark as handled </span>}
                defaultActive
                disabled={loading || isLoading}
              >
                <p className="bf-medium" style={{ marginBottom: 0 }}>
                  Check or uncheck to move the subscription to the handled or unhandled list accordingly.
                </p>
                <Checkbox
                  button
                  style={{ marginTop: 16, marginBottom: 8 }}
                  label="Handled"
                  type="checkbox"
                  checked={handled}
                  onChange={() => {
                    setHandled((prevState) => !prevState);
                    setHandledChanged(true);
                  }}
                />
              </Accordion.Item>
            )}
          </Accordion>
          <div className={styles.buttonGroup}>
            <Button variant="outline" type="button" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <SpinnerButton isSubmitting={loading || isLoading} buttonText={'Save changes'} />
          </div>
        </div>
      </form>
    );
  }
);

export default ManageMarketDrawer;
