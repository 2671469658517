import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@intility/bifrost-react/dist/bifrost-app.css';
import '@intility/bifrost-react-datepicker/datepicker.css';
import { UserDetails, AssignmentErrors, Tenants, Home, ConfigureTenantPage } from '../pages';
import Navigation from './navigation/Navigation';
import { ErrorBoundary } from '../components';
import styles from './App.module.css';

const App: FC = () => {
  return (
    <BrowserRouter>
      <ToastContainer className={styles.toast} />
      <Navigation>
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="tenants" element={<Tenants />} />
              <Route path="/tenant/:id?" element={<ConfigureTenantPage />} />
              <Route path="assignmentErrors" element={<AssignmentErrors />} />
              <Route path="user-details" element={<UserDetails />} />
            </Routes>
          </ErrorBoundary>
      </Navigation>
    </BrowserRouter>
  );
};

export default App;
