import { FC } from 'react';
import styles from './OrderSubmitPage.module.css';
import { Icon, Label } from '@intility/bifrost-react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import formatDate from 'helpers/formatDate/FormatDate';
import { OptionType } from 'models';
import { CountryCode } from 'models/OrderCountryCode';
import { OrderAvailability } from 'models/OrderAvailability';
import { OrderSku } from 'models/OrderSkus';
import { SegmentOption } from 'models/OrderSegment';

interface Availability extends OrderAvailability {
  label?: string;
}
interface ProductSkuId extends OrderSku {
  label?: string;
}
interface OrderSubmitPageSummaryProps {
  segment: SegmentOption;
  license: OptionType | null;
  productSkuId: ProductSkuId | null;
  countryCode: CountryCode;
  quantity: number | null;
  availability: Availability | null;
  date: string | null;
}

const OrderSubmitPageSummary: FC<OrderSubmitPageSummaryProps> = ({
  segment,
  license,
  productSkuId,
  countryCode,
  quantity,
  availability,
  date,
}) => {
  return (
    <div className={styles.pageMainContainer}>
      <div className={styles.formHeader}>
        <div className={styles.iconContainer}>
          <Icon icon={faList} className={styles.headerIcon}></Icon>
        </div>
        <div className={styles.textContainer}>
          <span className={styles.customh5BecauseBFSPadding + ' bf-h5'}>Order summary</span>
          <span className="bf-medium bf-strong">Step 3 of 3</span>
        </div>
      </div>
      <div className={styles.linees}></div>
      <h5 style={{ margin: 0, padding: 0 }} className="bf-h5">
        Subscription details
      </h5>
      <div className={styles.labelArea}>
        <div className={styles.labelStyling}>
          <Label>Segment</Label>
          <div>{segment?.label ? segment?.label : 'n/a'}</div>
        </div>
        <div className={styles.labelStyling}>
          <Label>License</Label>
          <div>{license?.label ? license?.label : 'n/a'}</div>
        </div>
        <div className={styles.labelStyling}>
          <Label>Product(Sku)</Label>
          <div>{productSkuId?.label ? productSkuId?.label : 'n/a'}</div>
        </div>
        <div className={styles.labelStyling}>
          <Label>Quantity</Label>
          <div>{quantity ?? 'n/a'}</div>
        </div>
      </div>
      <div className={styles.thinnerLiines}></div>
      <h5 style={{ margin: 0, padding: 0 }} className="bf-h5">
        Billing details
      </h5>
      <div className={styles.labelArea}>
        <div className={styles.labelStyling}>
          <Label>Country Code</Label>
          <div>{countryCode?.label ? countryCode?.label : 'n/a'}</div>
        </div>
        <div className={styles.labelStyling}>
          <Label>Availability</Label>
          <div>{availability?.label ? availability?.label : 'n/a'}</div>
        </div>
        <div className={styles.labelStyling}>
          <Label>End date</Label>
          <div>{date && formatDate(date) ? formatDate(date) : 'Using default P1M / P1Y / P3Y end date'}</div>
        </div>
      </div>
    </div>
  );
};
export default OrderSubmitPageSummary;
