import { FC, useEffect, useState } from 'react';
import { Control, Controller, SetFieldValue } from 'react-hook-form';
import { Badge } from '@intility/bifrost-react';
import Select from '@intility/bifrost-react-select';
import styles from './DateSelect.module.css';
import formatDate from 'helpers/formatDate/FormatDate';
import { addMonths, addYears, compareAsc, isSameDay, lastDayOfMonth, parseISO, set, setHours, subDays } from 'date-fns';
import { OrderAvailability } from 'models/OrderAvailability';
import { InnerSelectBadge } from './InnerSelectBadge';

export interface CleansedData {
  date: any;
  amount: number;
  license: any;
}
interface DateSelectProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  cleansedData: CleansedData[];
  availability: OrderAvailability | null;
}

const DateSelect: FC<DateSelectProps> = ({ cleansedData, control, setValue, availability }) => {
  const formatOptionLabel = ({
    label,
    badge,
    description,
  }: {
    label: string;
    value: string;
    badge: () => JSX.Element;
    description: string;
  }) => (
    <div className={styles.optionLabel}>
      {label}
      {badge()}
      <div className={styles.optionDescription}>{description}</div>
    </div>
  );

  const options = cleansedData
    .map((data) => {
      let description = '';
      let now = new Date();
      now = setHours(now, 12);

      if (isSameDay(parseISO(data.date), lastDayOfMonth(now))) {
        description = 'Last day of this month';
      } else if (isSameDay(parseISO(data.date), subDays(addMonths(now, 1), 1))) {
        description = 'One month (reneval date is minus one day)';
      } else if (isSameDay(parseISO(data.date), lastDayOfMonth(addMonths(now, 11)))) {
        description = 'Last day of last month but in one year';
      } else if (isSameDay(parseISO(data.date), lastDayOfMonth(addMonths(now, 35)))) {
        description = 'Last day of last month but in three years';
      } else if (isSameDay(parseISO(data.date), subDays(addYears(now, 1), 1))) {
        description = 'One year (reneval date is minus one day)';
      } else if (isSameDay(parseISO(data.date), subDays(addYears(now, 3), 1))) {
        description = 'Three years (reneval date is minus one day)';
      }

      return {
        label: `${formatDate(data.date)}`,
        value: data.date,
        badge: () => InnerSelectBadge(data),
        description: description,
        licenseLength: data.license.length,
        dateObj: parseISO(data.date),
      };
    })
    .sort((a, b) => {
      if (a.licenseLength === 0 && b.licenseLength !== 0) {
        return -1;
      } else if (a.licenseLength !== 0 && b.licenseLength === 0) {
        return 1;
      }
      if (a.description && !b.description) {
        return -1;
      } else if (!a.description && b.description) {
        return 1;
      }
      return compareAsc(a.dateObj, b.dateObj);
    });

  let defaultValue = options.length > 0 ? options[0].value : null;
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (selected) {
      setValue('date', selected, { shouldValidate: true });
    }
  }, [selected]);

  return (
    <>
      <Controller
        name="date"
        control={control}
        render={({ field: { value } }) => (
          <Select
            label="Choose end date"
            placeholder={formatDate(selected)}
            value={value}
            onChange={(item) => setSelected(item!.value)}
            options={options}
            formatOptionLabel={formatOptionLabel}
            className={styles.basicSelect}
            isDisabled={!availability}
          />
        )}
      />
      <p className="bf-medium" style={{ margin: 0, padding: 0 }}>
        Other subscriptions on the same date:
      </p>
      <div className={styles.badgeWrapper}>
        {cleansedData
          .filter((data) => data.date === selected)
          .flatMap((data) => data.license)
          .slice(0, 10)
          .map((item, index) => (
            <Badge key={index} pill className={styles.badges}>
              {item.label + ' ' + '(' + item.amount + ') '}
            </Badge>
          ))}
        {cleansedData.filter((data) => data.date === selected).flatMap((data) => data.license).length > 10 && (
          <Badge pill className={styles.badges}>
            + {cleansedData.filter((data) => data.date === selected).flatMap((data) => data.license).length - 10} more
          </Badge>
        )}
      </div>
    </>
  );
};

export default DateSelect;
