import { useCallback, useState } from 'react';
import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';

async function createAlsoGroupConfigCall(
  tenantId: string,
  groupId: string,
  groupName: string,
  subscriptionId: string,
  subscriptionName: string
): Promise<any> {
  return authorizedFetch(`${BaseAPIEndpoint}Also/ConnectSubscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tenantId: tenantId,
      groupId: groupId,
      groupName: groupName,
      subscriptionId: subscriptionId,
      subscriptionName: subscriptionName,
    }),
  });
}

export const usePostAlsoGroupConfig = () => {
  const [loading, setLoading] = useState(false);
  const [configError, setConfigError] = useState(false);

  const createAlsoGroupConfig = useCallback(
    async (tenantId: string, groupId: string, groupName: string, subscriptionId: string, subscriptionName: string) => {
      setLoading(true);
      try {
        const response = await createAlsoGroupConfigCall(
          tenantId,
          groupId,
          groupName,
          subscriptionId,
          subscriptionName
        );
        if (response.code === 200) {
          return true;
        } else {
          setConfigError(true);
        }
      } catch (errorMessage) {
        setConfigError(true);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    createAlsoGroupConfig,
    loading,
    setConfigError,
    configError,
  };
};
