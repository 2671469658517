import React, { FC, useState } from 'react';
import styles from './Connected.module.css';
import SearchBarFilter from '../../../components/SearchBarFilter';
import { Drawer, Table } from '@intility/bifrost-react';
import ShowConnectedSubscriptions from './ShowConnectedSubscriptions';
import ManageSubsDrawer from '../manageSubsForm/ManageSubsFormDrawerContent';
import { SubscriptionConnection } from 'models';
import { useSorting } from 'helpers/hooks';
import { MSProps } from 'models/TenantConfigProps';

interface ConnectedProps {
  MSProps: MSProps;
}

export const Connected: FC<ConnectedProps> = React.memo(({ MSProps }) => {
  const { subscriptionConnections, isLoadingConnectedSubscriptions, error, assuredTenantId, groups, handleMSMutate } =
    MSProps;

  const NameElement = (
    <>
      <span className={styles.defaultName}>Connected subscriptions</span>
    </>
  );
  const [showConnectedManageDrawer, setShowConnectedManageDrawer] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const { getHeaderCellProps, sortedData } = useSorting<SubscriptionConnection>(filteredData || []);

  return (
    <div className={styles.main}>
      <div>
        <SearchBarFilter
          title={NameElement}
          rawSubscriptionData={subscriptionConnections}
          setFilteredData={setFilteredData}
          filteredData={sortedData}
        />
        <Table noBorder>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell> {/* MUST exist */}
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('name')}>
                Subscription
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('termDuration')}>
                Term
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('termDuration')}>
                Billing term
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('commitmentEndDate')}>
                Renewal date
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('quantity')}>
                Licenses
              </Table.HeaderCell>
              <Table.HeaderCell style={{ color: 'var(--bfc-base-c-1)' }} {...getHeaderCellProps('assigned')}>
                Assigned
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell> {/* MUST exist */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <ShowConnectedSubscriptions
              rawSubscriptionData={subscriptionConnections}
              isloading={isLoadingConnectedSubscriptions}
              error={error}
              setShowConnectedManageDrawer={setShowConnectedManageDrawer}
              setSelectedSubscription={setSelectedSubscription}
              filteredData={sortedData}
            />
          </Table.Body>
        </Table>
      </div>
      <Drawer
        isOpen={showConnectedManageDrawer}
        onRequestClose={() => setShowConnectedManageDrawer(false)}
        overlay
        header={'Manage subscription'}
        className={styles.drawer}
      >
        <ManageSubsDrawer
          selectedSubscription={selectedSubscription}
          tenantId={assuredTenantId}
          groups={groups}
          setShowManageDrawer={setShowConnectedManageDrawer}
          usage={'connected'}
          handleMSMutate={handleMSMutate}
          MSProps={MSProps}
        />
      </Drawer>
    </div>
  );
});

export default Connected;
