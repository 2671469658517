export const getArrayFromLocalStorage = <T>(key: string, defaultValue: T[]): T[] => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error(`Error getting key "${key}" from localStorage`, error);
    return defaultValue;
  }
};

export const saveArrayToLocalStorage = <T>(key: string, value: T[]): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error saving key "${key}" to localStorage`, error);
  }
};

export const addToLocalStorage = <T extends { value: string }>(key: string, newItem: T): T[] => {
  try {
    const items = getArrayFromLocalStorage<T>(key, []);
    const itemExists = items.some(item => item.value === newItem.value);
    if (!itemExists) {
      items.push(newItem);
      if (items.length > 4) {
        items.shift();
      }
      saveArrayToLocalStorage(key, items);
    }
    
    return items;
  } catch (error) {
    console.error(`Error adding item to key "${key}" in localStorage`, error);
    return [];
  }
};
