import { BaseAPIEndpoint } from 'api/endpoints/apiEndpoints';
import { authorizedFetch } from 'auth/authorizedFetch';
import { OrderSku } from 'models/OrderSkus';

export const fetchSubscriptionOrderSkus = async (tenantId: string, productId: string) => {
  try {
    const response = await authorizedFetch<OrderSku[]>(`${BaseAPIEndpoint}Tenants/${tenantId}/product/${productId}`, {
      method: 'GET',
    });
    if (!response.data) {
      throw new Error('Error fetching product skus');
    }
    return response.data;
  } catch (error) {
    console.log('Error fetching product skus', error);
    throw error;
  }
};
