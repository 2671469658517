import { FC } from 'react';
import styles from './ProgressBar.module.css';
import { Icon } from '@intility/bifrost-react';
import { faArrowRightLong, faFileInvoiceDollar, faFileLines, faList } from '@fortawesome/free-solid-svg-icons';

interface ProgressBarProps {
  currentStep: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ currentStep }) => {
  switch (currentStep) {
    case 1:
      return (
        <div className={styles.progressContainer}>
          <span className={styles.activeStepCircle}>
            <Icon icon={faFileLines} className={styles.activeStepIcon}></Icon>
          </span>
          <Icon icon={faArrowRightLong} className={styles.baseIcon}></Icon>
          <Icon icon={faFileInvoiceDollar} className={styles.baseIcon}></Icon>
          <Icon icon={faArrowRightLong} className={styles.baseIcon}></Icon>
          <Icon icon={faList} className={styles.baseIcon}></Icon>
        </div>
      );
    case 2:
      return (
        <div className={styles.progressContainer}>
          <Icon icon={faFileLines} className={styles.activeStepIcon}></Icon>
          <Icon icon={faArrowRightLong} className={styles.activeStepIcon}></Icon>
          <span className={styles.activeStepCircle}>
            <Icon icon={faFileInvoiceDollar} className={styles.activeStepIcon}></Icon>
          </span>
          <Icon icon={faArrowRightLong} className={styles.baseIcon}></Icon>
          <Icon icon={faList} className={styles.baseIcon}></Icon>
        </div>
      );
    case 3:
      return (
        <div className={styles.progressContainer}>
          <Icon icon={faFileLines} className={styles.activeStepIcon}></Icon>
          <Icon icon={faArrowRightLong} className={styles.activeStepIcon}></Icon>
          <Icon icon={faFileInvoiceDollar} className={styles.activeStepIcon}></Icon>
          <Icon icon={faArrowRightLong} className={styles.activeStepIcon}></Icon>
          <span className={styles.activeStepCircle}>
            <Icon icon={faList} className={styles.activeStepIcon}></Icon>
          </span>
        </div>
      );
  }
};

export default ProgressBar;
