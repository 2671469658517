import {
  AuthenticationResult,
  BrowserCacheLocation,
  Configuration,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

/**
 * Configuration of authentication.
 *
 * Link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: `${import.meta.env.VITE_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
};

const msalInstance = await PublicClientApplication.createPublicClientApplication(MSAL_CONFIG);

msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.LOGIN_SUCCESS || message.eventType === EventType.SSO_SILENT_SUCCESS) {
    const result = message.payload as AuthenticationResult;
    msalInstance.setActiveAccount(result.account);
  }
});

export { msalInstance };
